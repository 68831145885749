<template>
  <sfs-base-list-filter
    title="Filter Work Orders"
    class="mb-0"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="translatedColumns(modelName, availableColumns)"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Work Order, Location, City, State"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #advanced-filters>
      <b-row>
        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="4"
        >
          <b-form-group
            label="All Clients"
            label-for="advanced-filter-client_id"
            label-sr-only
          >
            <client-select
              id="advanced-filter-client_id"
              placeholder="All Clients"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="4"
        >
          <b-form-group
            label="All Technicians"
            label-for="advanced-filter-assigned_to_id"
            label-sr-only
          >
            <user-select
              id="advanced-filter-assigned_to_id"
              placeholder="All Technicians"
              :value="assignedTo"
              @input="onAssignedToChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="4"
        >
          <b-form-group
            placeholder="All Locations"
            label-for="advanced-filter-location_id"
            label-sr-only
          >
            <location-select
              id="advanced-filter-location_id"
              :client-id="filters.client_id"
              placeholder="All Locations"
              :active-only="false"
              :value="location"
              @input="onLocationChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="6"
        >
          <b-form-group
            placeholder="All Statuses"
            label-for="advanced-filter-statuses"
            label-sr-only
          >
            <v-select
              id="advanced-filter-statuses"
              v-model="filters.statuses"
              :options="statusOptions"
              placeholder="All Statuses"
              :reduce="(status) => status.value"
              multiple
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Date Bucket After"
            label-for="advanced-filter-date_bucket_start_from"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-date_bucket_start_from"
              placeholder="Date Bucket From"
              v-model="filters.date_bucket_start_from"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="12"
          lg="6"
          xl="3"
        >
          <b-form-group
            label="Date Bucket Before"
            label-for="advanced-filter-date_bucket_end_to"
            label-sr-only
            content-cols-lg="12"
          >
            <sfs-date-picker
              id="advanced-filter-date_bucket_end_to"
              placeholder="Date Bucket To"
              v-model="filters.date_bucket_end_to"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-collapse id="collapse-more-filters">
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="6"
          >
            <b-form-group
              placeholder="All Sub Statuses"
              label-for="advanced-filter-sub_status"
              label-sr-only
            >
              <sub-status-select
                id="advanced-filter-sub_status"
                v-model="filters.sub_status"
                placeholder="All Sub Statuses"
                :reduce="(status) => status.value"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="mt-0 mb-4">
        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              label="All Services"
              label-for="advanced-filter-service_id"
              label-sr-only
            >
              <service-select
                id="advanced-filter-service_id"
                placeholder="All Services"
                :value="service"
                @input="onServiceChange"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="Checked In Between Dates"
              label-for="advanced-filter-checked_in_range"
              label-sr-only
            >
              <sfs-date-range-picker
                id="advanced-filter-checked_in_range"
                v-model="checkedInRange"
                placeholder="Checked In Between Dates"
                @on-change="onCheckedInRangeChanged"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group
              label="All States"
              label-for="advanced-filter-state"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-state"
                v-model="filters.state"
                type="text"
                placeholder="All States"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group

              label="Completed By Any Technician"
              label-for="advanced-filter-done_by_id"
              label-sr-only
            >
              <user-select
                id="advanced-filter-done_by_id"
                placeholder="Completed By Any Technician"
                :value="doneBy"
                @input="onDoneByChange"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="All Cities"
              label-for="advanced-filter-city"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-city"
                v-model="filters.city"
                type="text"
                placeholder="All Cities"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group
              label="All Counties"
              label-for="advanced-filter-county"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-county"
                v-model="filters.county"
                type="text"
                placeholder="All Counties"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              label="Invoiced Between Dates"
              label-for="advanced-filter-invoice_date_range"
              label-sr-only
            >
              <sfs-date-range-picker
                id="advanced-filter-invoice_date_range"
                v-model="invoiceDateRange"
                placeholder="Invoiced Between Dates"
                @on-change="onInvoiceDateRangeChanged"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="Linked to Work Order"
              label-for="advanced-filter-parent_id"
              label-sr-only
            >
              <work-order-select
                id="advanced-filter-parent_id"
                placeholder="Linked to Work Order"
                :value="parentWorkOrder"
                @input="onParentWorkOrderChange"
                :persist-state="true"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <hr class="mt-0 mb-4">

        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              label="All Completion Sources"
              label-for="advanced-filter-completion_sources"
              label-sr-only
            >
              <v-select
                id="advanced-filter-completion_sources"
                multiple
                v-model="filters.completion_sources"
                :options="completionSourceOptions"
                placeholder="All Completion Sources"
                :reduce="(source) => source.value"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group
              label="State"
              label-for="advanced-filter-assigned_to_state"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-assigned_to_state"
                v-model="filters.assigned_to_state"
                type="text"
                placeholder="All Technician States"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="Invoiced or Uninvoiced"
              label-for="advanced-filter-invoiced"
              label-sr-only
            >
              <b-form-radio-group
                id="advanced-filter-invoiced"
                v-model="filters.invoiced"
                :options="invoiceStatusOptions"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              label="All Cancellation Reasons"
              label-for="advanced-filter-reasons"
              label-sr-only
            >
              <v-select
                id="advanced-filter-reasons"
                v-model="filters.cancellation_reasons"
                :options="cancellationOptions"
                placeholder="All Cancellation Reasons"
                :reduce="(reason) => reason.value"
                multiple
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group
              label="All Managers"
              label-for="advanced-filter-manager_id"
              label-sr-only
            >
              <employee-select
                id="advanced-filter-manager_id"
                placeholder="All Managers"
                :value="manager"
                @input="onManagerChange"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="Technician Paid or Unpaid"
              label-for="advanced-filter-technician_paid"
              label-sr-only
            >
              <b-form-radio-group
                id="advanced-filter-technician_paid"
                v-model="filters.technician_paid"
                :options="technicianPaidStatusOptions"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              label="Checked Out Between Dates"
              label-for="advanced-filter-checked_out_range"
              label-sr-only
            >
              <sfs-date-range-picker
                id="advanced-filter-checked_out_range"
                v-model="checkedOutRange"
                placeholder="Checked Out Between Dates"
                @on-change="onCheckedOutRangeChanged"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group

              :label="fieldLabels.description"
              label-for="advanced-filter-description"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-description"
                v-model="filters.description"
                type="text"
                :placeholder="fieldLabels.description"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="3"
          >
            <b-form-group
              :label="fieldLabels.number"
              label-for="advanced-filter-number"
              label-sr-only
            >
              <b-form-input
                id="advanced-filter-number"
                v-model="filters.number"
                type="text"
                :placeholder="fieldLabels.number"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="5"
          >
            <b-form-group
              :label="fieldLabels.check_in_distance"
              label-for="advanced-filter-checkInDistance"
              label-sr-only
            >
              <v-select
                id="advanced-filter-checkInDistance"
                v-model="filters.check_in_distance"
                :options="distanceOptions"
                taggable
                @option:created="createTag"
                placeholder="Check in distance above"
                :reduce="(distance) => distance.value"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="12"
            lg="6"
            xl="4"
          >
            <b-form-group
              label="Technician Paid or Unpaid"
              label-for="advanced-filter-no_service"
              label-sr-only
            >
              <b-form-checkbox
                id="advanced-filter-no_service"
                v-model="filters.no_service"
              >
                Work Orders Without Service
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </b-collapse>

      <b-row class="mb-3">
        <b-col>
          <b-link v-b-toggle:collapse-more-filters>
            <span class="when-open">Show Less</span><span class="when-closed">Show More</span>
          </b-link>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import LocationSelect from '@/components/admin/locations/Select.vue';
import ClientSelect from '@/components/admin/clients/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import UserSelect from '@/components/admin/users/Select.vue';
import EmployeeSelect from '@/components/admin/users/EmployeeSelect.vue';
import WorkOrderSelect from '@/components/admin/workOrders/Select.vue';
import SubStatusSelect from '@/components/admin/workOrders/SubStatusSelect.vue';

import listFilterMixin from '@/mixins/listFilterMixin';
import translationMixin from '@/mixins/translationMixin';

import {
  WORK_ORDER_TABLE_COLUMN_OPTIONS,
  WORK_ORDER_TABLE_DEFAULT_COLUMNS,
  WORK_ORDER_TABLE_NAME,
  WORK_ORDER_FORM_LABELS,
  WORK_ORDER_MODEL_NAME,
} from '@/constants/workOrders';
import { INVOICE_TABLE_FILTER_OPTIONS } from '@/constants/invoices';
import { TECHNICIAN_PAYMENT_TABLE_FILTER_OPTIONS } from '@/constants/technicianPayments';

export default {
  name: 'WorkOrderListFilter',
  mixins: [listFilterMixin, translationMixin],
  components: {
    LocationSelect,
    ClientSelect,
    ServiceSelect,
    UserSelect,
    EmployeeSelect,
    WorkOrderSelect,
    SubStatusSelect,
  },
  data() {
    return {
      cancellationOptions: [],
      availableColumns: WORK_ORDER_TABLE_COLUMN_OPTIONS,
      tableName: WORK_ORDER_TABLE_NAME,
      modelName: WORK_ORDER_MODEL_NAME,
      fieldLabels: WORK_ORDER_FORM_LABELS,
      statusOptions: [],
      completionSourceOptions: [],
      checkedInRange: [
        this.initialFilters.checked_in_from,
        this.initialFilters.checked_in_to,
      ],
      checkedOutRange: [
        this.initialFilters.checked_out_from,
        this.initialFilters.checked_out_to,
      ],
      invoiceDateRange: [
        this.initialFilters.invoice_date_from,
        this.initialFilters.invoice_date_to,
      ],
      invoiceStatusOptions: INVOICE_TABLE_FILTER_OPTIONS,
      technicianPaidStatusOptions: TECHNICIAN_PAYMENT_TABLE_FILTER_OPTIONS,
      distanceOptions: [
        {id: 1, label: '0.25 mi', value: 0.25},
        {id: 2, label: '0.5 mi', value: 0.5},
        {id: 3, label: '0.75 mi', value: 0.75},
        {id: 4, label: '1 mi', value: 1},
      ]
    };
  },
  mounted() {
    Promise.all([
      this.getStatusOptions(),
      this.getCancellationOptions(),
      this.getCompletionSourceOptions(),
      this.checkDistanceOptions(),
    ]).then(() => {
      this.loadInitialFilters();
    });
  },
  methods: {
    createTag(newTag) {
      const match = newTag.label.match(/\d+(\.\d+)?/);
      const numericPart = match ? match[0] : '1';
      newTag.value = numericPart;
      newTag.id =  numericPart;
      this.filters.check_in_distance = newTag;
      this.distanceOptions.push(newTag);
    },
    checkDistanceOptions() {
      if(this.filters.check_in_distance != undefined && parseInt(this.filters.check_in_distance) > 5){
        this.distanceOptions.push({
          id: this.filters.check_in_distance,
          label: `${this.filters.check_in_distance} mi`,
          value: this.filters.check_in_distance
        });
      }
    },
    applyPresetFilter(filters, columns) {
      this.filters = filters;
      this.displayedColumns = columns;
      this.applyFilters();
    },
    onDefaultPresetApplied() {
      this.applyPresetFilter({}, WORK_ORDER_TABLE_DEFAULT_COLUMNS);
    },
    onLocationChange(location) {
      this.filters.location_id = location ? location.id.toString() : '';
      this.location = location;
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    onServiceChange(service) {
      this.filters.service_id = service ? service.id.toString() : '';
      this.service = service;
    },
    onAssignedToChange(user) {
      this.filters.assigned_to_id = user ? user.id.toString() : '';
      this.assignedTo = user;
    },
    onDoneByChange(user) {
      this.filters.done_by_id = user ? user.id.toString() : '';
      this.doneBy = user;
    },
    onManagerChange(user) {
      this.filters.manager_id = user ? user.id.toString() : '';
      this.manager = user;
    },
    onParentWorkOrderChange(parent) {
      this.filters.parent_id = parent ? parent.id.toString() : '';
      this.parentWorkOrder = parent;
    },
    onCheckedInRangeChanged(dates) {
      if (dates[0]) {
        this.filters.checked_in_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.checked_in_from = '';
      }
      if (dates[1]) {
        this.filters.checked_in_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.checked_in_to = '';
      }
    },
    onCheckedOutRangeChanged(dates) {
      if (dates[0]) {
        this.filters.checked_out_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.checked_out_from = '';
      }
      if (dates[1]) {
        this.filters.checked_out_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.checked_out_to = '';
      }
    },
    onInvoiceDateRangeChanged(dates) {
      if (dates[0]) {
        this.filters.invoice_date_from = this.$options.filters.dateFormat(dates[0].toISOString());
      } else {
        this.filters.invoice_date_from = '';
      }
      if (dates[1]) {
        this.filters.invoice_date_to = this.$options.filters.dateFormat(dates[1].toISOString());
      } else {
        this.filters.invoice_date_to = '';
      }
    },
    getStatusOptions() {
      this.$workOrdersAPI.getStatusOptions().then((response) => {
        const options = response.data.status_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.statusOptions = [...options];
      });
    },
    async getCancellationOptions() {
        const response = await this.$workOrdersAPI.getCancellationOptions();
        const options = response.data.cancellation_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.cancellationOptions = [...options];
    },
    getCompletionSourceOptions() {
      this.$workOrdersAPI.getCompletionSourceOptions().then((response) => {
        const options = response.data.completion_source_options.map((option) => {
          return { label: option[0], value: option[1] };
        });
        this.completionSourceOptions = [...options];
      });
    },
    loadInitialFilters() {
      const loadLocation = new Promise((resolve) => {
        if (this.initialFilters.location_id) {
          this.$locationsAPI.get(this.initialFilters.location_id).then((location) => {
            resolve(location);
          });
        } else {
          resolve(null);
        }
      });

      const loadClient = new Promise((resolve) => {
        if (this.initialFilters.client_id) {
          this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
            resolve(client);
          });
        } else {
          resolve(null);
        }
      });

      const loadService = new Promise((resolve) => {
        if (this.initialFilters.service_id) {
          this.$servicesAPI.get(this.initialFilters.service_id).then((service) => {
            resolve(service);
          });
        } else {
          resolve(null);
        }
      });

      const loadAssignedTo = new Promise((resolve) => {
        if (this.initialFilters.assigned_to_id) {
          this.$usersAPI.get(this.initialFilters.assigned_to_id).then((assignedTo) => {
            resolve(assignedTo);
          });
        } else {
          resolve(null);
        }
      });

      const loadDoneBy = new Promise((resolve) => {
        if (this.initialFilters.done_by_id) {
          this.$usersAPI.get(this.initialFilters.done_by_id).then((doneBy) => {
            resolve(doneBy);
          });
        } else {
          resolve(null);
        }
      });

      const loadManager = new Promise((resolve) => {
        if (this.initialFilters.manager_id) {
          this.$usersAPI.get(this.initialFilters.manager_id).then((manager) => {
            resolve(manager);
          });
        } else {
          resolve(null);
        }
      });

      const loadParentWorkOrder = new Promise((resolve) => {
        if (this.initialFilters.parent_id) {
          this.$workOrdersAPI.get(this.initialFilters.parent_id).then((parent) => {
            resolve(parent);
          });
        } else {
          resolve(null);
        }
      });

      // The order of the resolved promises is preserved so we are able to use
      // indexes to fetch the data.
      Promise.all([loadLocation, loadClient, loadAssignedTo, loadDoneBy, loadManager, loadService, loadParentWorkOrder]).then((values) => {
        this.location = values[0];
        this.client = values[1];
        this.assignedTo = values[2];
        this.doneBy = values[3];
        this.manager = values[4];
        this.service = values[5];
        this.parentWorkOrder = values[6];
        this.applyFilters();
      });
    },
  },
};
</script>
