import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  get(id) {
    return axios.get(adminPath(`/integration_references/${id}`)).then((response) => an('IntegrationReference', response.data));
  },
  getNetSuiteRecords(params) {
    return axios.get(adminPath('/integration_references/netsuite?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data.map((record) => an('NetSuiteRecord', record)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  createNetSuiteReference(data) {
    return axios
        .post(adminPath('/integration_references/netsuite'), {
          ...data,
        })
        .then((response) => an('IntegrationReference', response.data));
  },
  getAssignmentStatusOptions(id, params) {
    return axios.get(adminPath(`/integration_references/${id}/dollar_general_fexa_assignment_status_options?`) + prepareQueryString(params)).then((response) => {
      return {
        statuses: response.data.map((record) => an('DollarGeneralFexaAssignmentStatusOption', record)),
        total: response.data.length,
      };
    });
  },
  updateAssignmentStatus(id, data) {
    return axios
      .patch(adminPath(`/integration_references/${id}/dollar_general_fexa_assignment_status`), {
        ...data,
      })
      .then((response) => an('IntegrationReference', response.data));
  },
  getDollarGeneralFexaQuoteProductOptions(params = {}) {
    return axios.get(adminPath('/integration_references/dollar_general_fexa_quote_product_options?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getDollarGeneralFexaQuoteCapexDescriptionOptions(params = {}) {
    return axios.get(adminPath('/integration_references/dollar_general_fexa_quote_capex_description_options?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  getDollarGeneralFexaQuoteActivityCodeOptions(params = {}) {
    return axios.get(adminPath('/integration_references/dollar_general_fexa_quote_activity_code_options?') + prepareQueryString(params)).then((response) => {
      return {
        records: response.data,
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
};
