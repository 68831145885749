<template>
  <b-form @submit.stop.prevent="onSubmit">
    <b-row>
      <b-col cols="12">
        <div class="d-flex">
          <div class="flex-shrink-0 mt-1">
            <i class="fa fa-3x fa-exclamation text-primary" />
          </div>
          <div class="flex-grow-1 ml-3">
            <h3 class="mb-1">
              Multi-Factor Authentication
            </h3>
            <p class="mb-0">
              Please enter the code from your multi-factor authentication app.
            </p>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-alert
      v-model="incorrectCode"
      variant="danger"
      dismissible
    >
      The one-time code you entered is incorrect.
    </b-alert>
    <b-alert
      :show="hasBaseServerErrors()"
      variant="danger"
      dismissible
    >
      <p
        class="mb-0"
        v-for="(error, index) in baseServerErrors"
        :key="index"
      >
        {{ error }}
      </p>
    </b-alert>

    <div>
      <b-form-group
        :label="fieldLabels.otp_attempt"
        label-for="user-otp_attempt"
      >
        <b-form-input
          id="user-otp_attempt"
          class="form-control-dark"
          :value="$v.form.otp_attempt.$model"
          @input="onOtpAttemptChanged"
          :state="$v.form.otp_attempt.$dirty ? !$v.form.otp_attempt.$error : null"
          type="text"
          autofocus
        />
        <b-form-invalid-feedback
          v-if="$v.form.otp_attempt.$dirty"
          id="user-otp_attempt-feedback"
        >
          <span v-if="!$v.form.otp_attempt.required">Please enter a one-time code.</span>
          <span v-if="!$v.form.otp_attempt.serverFailed">
            {{ serverErrors.otp_attempt }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <b-row class="form-group">
      <b-col>
        <b-button
          type="submit"
          variant="primary"
          block
        >
          <b-spinner
            v-if="processing"
            small
            label="Logging In..."
            class="mr-1"
          />
          <i
            v-else
            class="fa fa-fw fa-sign-in-alt mr-1"
          />
          Verify Code
        </b-button>
      </b-col>
    </b-row>
    <b-link @click="backToLogin">
      Back to Login
    </b-link>
  </b-form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';

export default {
  name: 'RegisterForm',
  mixins: [formMixin],
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        email: this.email,
        password: this.password,
        otp_attempt: null,
      },
      fieldLabels: {
        otp_attempt: 'One-time Code',
      },
      incorrectCode: false,
      processing: false,
      qrCode: null,
    };
  },
  validations: {
    form: {
      otp_attempt: {
        required,
        serverFailed() {
          return !this.hasServerErrors('otp_attempt');
        },
      },
    },
  },
  methods: {
    onSubmit() {
      this.resetServerErrors();
      this.$v.$touch();

      if (this.$v.$anyError) {
        return;
      }
      this.processing = true;
      this.$store
        .dispatch('login', this.form)
        .then(() => {
          this.$emit('on-login');
        })
        .catch((error) => {
          this.processing = false;
          this.processServerErrors(error?.response?.data?.errors);
          this.incorrectCode = !this.hasBaseServerErrors();
        });
    },
    onOtpAttemptChanged(newCode) {
      this.form.otp_attempt = (newCode || '').replace(/\s/g, '');
    },
    backToLogin() {
      this.$emit('on-reset-login');
    },
  },
};
</script>
