<template>
  <div v-if="service">
    <base-page-heading
      :title="service.name"
      show-back-button
    >
      <template #secondary-title>
        <b-badge variant="secondary">
          {{ service.category | humanizedFormat | titlecaseFormat }}
        </b-badge>
      </template>
      <template #extra>
        <b-button
          v-if="$can('update', 'Service')"
          variant="primary"
          v-b-modal.service-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Service"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="service.id"
          reference-type="Service"
          :current-integration-reference-id="service.netsuite_reference_id"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('destroy', 'Service')"
            @click="toggleActive"
          >
            <i
              v-if="!service.deleted_at"
              class="fa fa-trash mr-1"
            />
            <i
              v-else
              class="fa fa-undo mr-1"
            />
            {{ toggleActiveTooltipText }}
          </b-dropdown-item>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: service.id, model: 'Service' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col
          md="12"
          lg="7"
          xl="7"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Client
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="service.client">
                  <b-link :to="{ name: 'client', params: { id: service.client_id } }">
                    {{ service.client.name }}
                  </b-link>
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Active
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ service.deleted_at ? 'No' : 'Yes' }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Description
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ service.description }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Add On
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ service.add_on ? 'Yes' : 'No' }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <hr class="mt-0 mb-3">

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Minimum Days Between Services
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ minimumDaysBetweenService }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Estimated Hours to Complete
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ estimatedHours }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="12"
          lg="5"
          xl="5"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col
                  v-b-tooltip.hover
                  title="Selecting a technician is mandatory if this Service is selected"
                >
                  Charges Require a Technician
                  <i class="far fa-question-circle" />
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ service.work_order_charges_require_user ? 'Yes' : 'No' }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col
                  v-b-tooltip.hover
                  title="Determines whether the Charge or Work Order is used to determine tax"
                >
                  Tax Categorization
                  <i class="far fa-question-circle ml-1" />
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ service.tax_categorized_via_work_order ? 'Work Order' : 'Charge' }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Technician Payment GL Code
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ technicianPaymentGLCodeName }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Invoice GL Code
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ invoiceGLCodeName }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Customer ID
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="service.customer_identifier">
                  {{ service.customer_identifier }}
                </b-col>
                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>

    <!-- Modals -->
    <edit-modal
      v-if="$can('update', 'Service')"
      v-model="service"
    />
  </div>
</template>

<script>
import pluralize from 'pluralize';
import EditModal from '@/components/admin/services/EditModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { DateTime } from 'luxon';

export default {
  name: 'ServicesShow',
  components: {
    EditModal,
    NetSuiteFormPopover,
  },
  data() {
    return {
      service: null,
      totalWorkOrders: 0,
      totalTechnicianServices: 0,
    };
  },
  mounted() {
    this.getService();
    this.getWorkOrders();
  },
  methods: {
    getService() {
      this.$servicesAPI
        .get(this.$route.params.id)
        .then((service) => {
          this.service = service;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    toggleActive() {
      this.$swal({
        text: this.toggleActiveConfirmText,
        preConfirm: () => this.$servicesAPI.update(this.$route.params.id, { deleted_at: !this.service.deleted_at ? DateTime.now().toISODate() : null }),
      }).then((result) => {
        if (result.value) {
          this.service = result.value;
        }
        result.timer;
      });
    },
    getWorkOrders() {
      this.$workOrdersAPI.getWorkOrders({ service_id: this.$route.params.id, per_page: 1 }).then((data) => {
        this.totalWorkOrders = this.$options.filters.numberWithDelimeter(data.total);
      });
    },
    getRecurringWorkOrders() {
      this.$technicianServicesAPI.getTechnicianServices({ service_id: this.$route.params.id, active: true, per_page: 1 }).then((data) => {
        this.totalRecurringWorkOrders = this.$options.filters.numberWithDelimeter(data.total);
      });
    },
  },
  computed: {
    toggleActiveTooltipText() {
      return !this.service.deleted_at ? 'Deactivate' : 'Reactivate';
    },
    toggleActiveConfirmText() {
      return !this.service.deleted_at
        ? 'Are you sure you want to deactivate the Service?'
        : 'Are you sure you want to reactivate the Service?';
    },
    minimumDaysBetweenService() {
      const daysBetweenServices = this.service.minimum_days_between_service;
      return `${daysBetweenServices} ${pluralize('Day', daysBetweenServices)}`;
    },
    estimatedHours() {
      const estimatedHours = this.$options.filters.numberWithDelimeter(this.service.estimated_hours) || 0;
      return `${estimatedHours} ${pluralize('Hour', estimatedHours)}`;
    },
    technicianPaymentGLCodeName() {
      return this.service.technician_payment_gl_code?.name || '-';
    },
    invoiceGLCodeName() {
      return this.service.invoice_gl_code?.name || '-';
    },
  }
};
</script>
