<template>
  <div v-if="vendor">
    <base-page-heading
      :title="vendor.name"
      show-back-button
    >
      <template #extra>
        <b-button
          v-if="$can('update', 'Vendor')"
          variant="primary"
          v-b-modal.vendor-edit-modal
          class="mr-2"
          v-b-tooltip.hover
          title="Edit the Vendor"
        >
          <i class="fa fa-pencil-alt mr-1" /> Edit
        </b-button>

        <net-suite-form-popover
          v-if="$can('read', 'IntegrationReference')"
          :reference-id="vendor.id"
          reference-type="Vendor"
          :current-integration-reference-id="vendor.netsuite_reference_id"
        />

        <b-dropdown
          variant="outline-secondary"
          no-caret
          right
        >
          <template #button-content>
            <i class="fa fa-ellipsis-v" />
          </template>

          <b-dropdown-item
            v-if="$can('read', 'Version')"
            :to="{ name: 'versions', query: { model_id: vendor.id, model: 'Vendor' } }"
          >
            <i class="fa fa-glasses mr-1" />
            Audit Log
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </base-page-heading>
    <div class="content pt-5">
      <b-row class="mb-3">
        <b-col
          md="12"
          lg="7"
          xl="7"
        >
          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Account Number
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  {{ vendor.account_number }}
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Billing Address
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col>
                  <div>{{ vendor.address }}</div>
                  <div>
                    {{
                      {
                        city: vendor.city,
                        state: vendor.state,
                        zip_code: vendor.zip_code,
                      } | locationFormat
                    }}
                  </div>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  PO Attention To
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="vendor.attention_to">
                  {{ vendor.attention_to }}
                </b-col>

                <b-col v-else>
                  -
                </b-col>
              </b-row>
            </b-col>

            <b-col
              cols="12"
              sm="6"
            >
              <b-row class="text-muted font-w500 font-size-sm mb-1">
                <b-col>
                  Tax Exempt
                </b-col>
              </b-row>

              <b-row class="mb-3">
                <b-col v-if="vendor.tax_exempt">
                  Yes
                </b-col>
                <b-col v-else>
                  No
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>

        <b-col
          md="12"
          lg="5"
          xl="5"
        >
          <contact-infos-list
            :list-func="$contactInfosAPI.getVendorsContactInfos"
            :create-func="$contactInfosAPI.createVendorsContactInfos"
            :reference-id="vendor.id"
            reference-type="Vendor"
          />
        </b-col>
      </b-row>

      <b-tabs>
        <template #tabs-end>
          <div
            class="ml-auto"
          >
            <b-button
              v-if="$can('create', 'Supply')"
              variant="outline-secondary"
              v-b-modal.supply-add-modal
              v-b-tooltip.hover
              title="Create a new Supply"
            >
              <i class="fa fa-plus" />
              Add Supplies
            </b-button>
            <sfs-download-button
              v-b-tooltip.hover
              class="ml-2"
              :provider-params="{ ...suppliesFilters, vendor_id: vendor.id }"
              filename="Supplies.csv"
              :file-provider="$suppliesAPI.getSpreadsheet"
              title="Download spreadsheet"
            />
          </div>
        </template>
        <b-tab title="Supplies">
          <b-row>
            <b-col>
              <b-row class="pt-3">
                <b-col>
                  <supplies-list-filter
                    :columns="suppliesColumns"
                    :table-name="suppliesTableName"
                    :default-columns="suppliesDefaultColumns"
                    :initial-filters="suppliesFilters"
                    @filters-applied="applySuppliesFilters"
                  />
                </b-col>
              </b-row>

              <supplies-table
                :filters="suppliesFilters"
                :columns="suppliesColumns"
                :append-to-query="false"
                :key="suppliesTableKey"
                :vendor-id="vendor.id"
              />
            </b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </div>

    <!-- Modals -->
    <vendors-edit-modal
      v-if="$can('update', 'Vendor')"
      v-model="vendor"
    />
    <supplies-new-modal
      v-if="$can('create', 'Supply')"
      :vendor="vendor"
      :create-provider="$suppliesAPI.create"
      @on-save="refreshSupplies"
    />
  </div>
</template>

<script>
import VendorsEditModal from '@/components/admin/vendors/EditModal.vue';
import ContactInfosList from '@/components/admin/contactInfos/CardList.vue';
import SuppliesTable from '@/components/admin/supplies/Table.vue';
import SuppliesListFilter from '@/components/admin/supplies/ListFilter.vue';
import SuppliesNewModal from '@/components/admin/supplies/NewModal.vue';
import NetSuiteFormPopover from '@/components/admin/integrationReferences/NetSuiteFormPopover.vue';
import { VENDOR_SUPPLY_TABLE_NAME, VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS } from '@/constants/supplies';

export default {
  name: 'VendorsShow',
  components: {
    VendorsEditModal,
    ContactInfosList,
    SuppliesTable,
    SuppliesListFilter,
    SuppliesNewModal,
    NetSuiteFormPopover,
  },
  data() {
    return {
      vendor: null,
      suppliesTableKey: Date.now() + 1,
      suppliesTableName: VENDOR_SUPPLY_TABLE_NAME,
      suppliesDefaultColumns: VENDOR_SUPPLY_TABLE_DEFAULT_COLUMNS,
      suppliesFilters: {
        text: '',
        item_number: '',
        description: '',
        city: '',
        state: '',
        category: '',
        active: true,
      },
    };
  },
  mounted() {
    this.getVendor();
  },
  computed: {
    suppliesColumns() {
      return this.$store.getters.columnsForTable(this.suppliesTableName);
    },
  },
  methods: {
    getVendor() {
      this.$vendorsAPI
        .get(this.$route.params.id)
        .then((vendor) => {
          this.vendor = vendor;
        })
        .catch(() => {
          this.$router.push('/404');
        });
    },
    refreshContactInfos() {
      this.contactInfosTableKey++;
    },
    refreshSupplies() {
      this.suppliesTableKey++;
    },
    applySuppliesFilters({ filters }) {
      this.suppliesFilters = Object.assign({}, filters);
    },
  },
};
</script>
