<template>
  <sfs-base-table
    :key="tableKey"
    :data-provider="$quotesAPI.getQuotes"
    provider-key="quotes"
    :columns="translatedColumns(modelName, columns)"
    :filters="filters"
    :current-page="currentPage"
    :per-page="perPage"
    :show-per-page="showPerPage"
    @total-rows-changed="(totalRows) => $emit('total-rows-changed', totalRows)"
  >
    <template #cell(number)="data">
      <b-link :to="{ name: 'quote', params: { id: data.item.id } }">
        {{ data.item.number }}
      </b-link>
    </template>
    <template #cell(status)="data">
      <div class="text-nowrap">
        <status-badge
          :status="data.item.status"
          :status-variants="statusVariants"
        />
      </div>
    </template>
    <template #cell(date)="data">
      {{ data.item.date | dateFormat }}
    </template>
    <template #cell(location_id)="data">
      <b-link
        :to="{ name: 'location', params: { id: data.item.location_id } }"
        v-b-tooltip.hover.window
        :title="`${data.item.location.street}, ${data.item.location.city}, ${data.item.location.state}, ${data.item.location.zip_code}`"
      >
        {{ data.item.location.store_number }}
        <span v-if="data.item.location.name">- {{ data.item.location.name }}</span>
      </b-link>
      <div class="text-nowrap font-size-sm text-secondary">
        {{ { city: data.item.location.city, state: data.item.location.state } | locationFormat }}
      </div>
    </template>
    <template #cell(client_id)="data">
      <b-link
        v-if="data.item.client"
        :to="{ name: 'client', params: { id: data.item.client_id } }"
      >
        {{ data.item.client.name }}
      </b-link>
    </template>
    <template #cell(cost)="data">
      <money-format
        :value="data.item.cost.amount"
        :currency-code="data.item.cost.currency"
        subunits-value
      />
    </template>

    <template #cell(estimated_taxes)="data">
      <money-format
        :value="data.item.estimated_taxes.amount"
        :currency-code="data.item.estimated_taxes.currency"
        subunits-value
      />
    </template>
    <template #cell(price)="data">
      <money-format
        :value="data.item.price.amount + data.item.estimated_taxes.amount"
        :currency-code="data.item.price.currency"
        subunits-value
      />
    </template>
    <template #cell(actions)="data">
      <b-button
        size="sm"
        variant="link"
        v-b-tooltip.hover.top.window
        title="Convert the Quote to Charges"
        @click="() => converQuoteToCharges(data.item.id)"
      >
        <i class="fa fa-magic" />
      </b-button>
      <b-dropdown
        variant="link"
        size="sm"
        boundary="window"
        no-caret
      >
        <template #button-content>
          <i class="fa fa-ellipsis-h" />
        </template>

        <b-dropdown-item v-b-modal="`quote-${data.item.id}-email-modal`">
          <i class="fa fa-envelope mr-1" />
          Email
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('propose', data.item)"
          v-b-modal="`quote-${data.item.id}-propose-modal`"
        >
          <i class="fa fa-share mr-1" />
          Propose
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('accept', data.item)"
          @click="() => accept(data.item.id)"
        >
          <i class="fa fa-check mr-1" />
          Accept
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('decline', data.item)"
          @click="() => decline(data.item.id)"
        >
          <i class="far fa-thumbs-down mr-1" />
          Decline
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('cancel', data.item)"
          @click="() => cancel(data.item.id)"
        >
          <i class="fa fa-trash mr-1" />
          Cancel
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$can('uncancel', data.item)"
          @click="() => reopen(data.item.id)"
        >
          <i class="fa fa-undo mr-1" />
          Reopen
        </b-dropdown-item>
      </b-dropdown>
      <email-modal
        :value="data.item"
        @input="refreshTable"
      />
      <propose-modal
        :value="data.item"
        @input="refreshTable"
      />
    </template>
  </sfs-base-table>
</template>

<script>
import StatusBadge from '@/components/shared/StatusBadge.vue';
import tablePropsMixin from '@/mixins/tablePropsMixin';
import translationMixin from '@/mixins/translationMixin';
import { QUOTE_TABLE_DEFAULT_COLUMNS, QUOTE_STATUS_VARIANTS, QUOTE_MODEL_NAME } from '@/constants/quotes';
import EmailModal from '@/components/admin/quotes/EmailModal.vue';
import ProposeModal from '@/components/admin/quotes/ProposeModal.vue';

export default {
  name: 'QuotesTable',
  components: {
    StatusBadge,
    EmailModal,
    ProposeModal,
  },
  mixins: [tablePropsMixin, translationMixin],
  props: {
    columns: {
      type: Array,
      default: () => QUOTE_TABLE_DEFAULT_COLUMNS,
    },
  },
  data() {
    return {
      statusVariants: QUOTE_STATUS_VARIANTS,
      modelName: QUOTE_MODEL_NAME,
    };
  },
  methods: {
    converQuoteToCharges(id) {
      this.$swal({
        text: 'Are you sure you want to convert the Quote to Charges for this Work Order?',
        preConfirm: () => this.$quotesAPI.convertToCharges(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    accept(id) {
      this.$swal({
        text: `Are you sure you want to accept this Quote on behalf of the ${this.$store.getters.translate('client', 'Client')}?`,
        preConfirm: () => this.$quotesAPI.accept(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    decline(id) {
      this.$swal({
        text: `Are you sure you want to decline this Quote on behalf of the ${this.$store.getters.translate('client', 'Client')}?`,
        preConfirm: () => this.$quotesAPI.decline(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    cancel(id) {
      this.$swal({
        text: 'Are you sure you want to cancel this Quote?',
        preConfirm: () => this.$quotesAPI.cancel(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
    reopen(id) {
      this.$swal({
        text: 'Are you sure you want to reopen this Quote?',
        preConfirm: () => this.$quotesAPI.uncancel(id),
      }).then((result) => {
        if (result.value) {
          this.refreshTable();
        }
        result.timer;
      });
    },
  },
};
</script>
