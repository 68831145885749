import axios from 'axios';
import prepareQueryString from '@/utils/prepareQueryString';
import fileFormDataSubmission from '@/utils/fileFormDataSubmission';
import { subject as an } from '@casl/ability';
import { adminPath } from '@/api/helpers/apiPath';

export default {
  getWorkOrders(params) {
    return axios.get(adminPath('/work_orders?') + prepareQueryString(params)).then((response) => {
      return {
        workOrders: response.data.map((workOrder) => an('WorkOrder', workOrder)),
        total: response.headers['x-pagination'] && JSON.parse(response.headers['x-pagination']).total,
      };
    });
  },
  get(id) {
    return axios.get(adminPath(`/work_orders/${id}`)).then((response) => an('WorkOrder', response.data));
  },
  getPDF(params) {
    return axios.get(adminPath(`/work_orders/${params.id}.pdf`), { responseType: 'blob' });
  },
  getStatusOptions() {
    return axios.get(adminPath('/work_orders/status_options'));
  },
  getSubStatusOptions() {
    return axios.get(adminPath('/work_orders/sub_status_options'));
  },
  getCancellationOptions() {
    return axios.get(adminPath('/work_orders/cancellation_options'));
  },
  getCompletionSourceOptions() {
    return axios.get(adminPath('/work_orders/completion_source_options'));
  },
  update(id, data) {
    return axios
      .patch(adminPath(`/work_orders/${id}`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  create(data) {
    return axios
      .post(adminPath('/work_orders'), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  email(id, data) {
    return axios
      .post(adminPath(`/work_orders/${id}/email`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  emailWorkOrderPDFs(params, emails = []) {
    return axios.post(adminPath('/work_orders/email_pdfs?') + prepareQueryString(params), { emails: emails, responseType: 'blob' });
  },
  destroy(id, data) {
    return axios
      .delete(adminPath(`/work_orders/${id}`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  uncancel(id) {
    return axios.patch(adminPath(`/work_orders/${id}/uncancel`)).then((response) => an('WorkOrder', response.data));
  },
  getSpreadsheet(params) {
    return axios.get(adminPath('/work_orders.csv?') + prepareQueryString(params), { responseType: 'blob' });
  },
  assign(id, data) {
    return axios
      .patch(adminPath(`/work_orders/${id}/assign`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  reassign(id, data) {
    return axios
      .patch(adminPath(`/work_orders/${id}/reassign`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  complete(id, data) {
    return axios
      .patch(adminPath(`/work_orders/${id}/complete`), {
        ...data,
      })
      .then((response) => an('WorkOrder', response.data));
  },
  uploadOfficetraxPhotos(id) {
    return axios
      .post(adminPath(`/work_orders/${id}/upload_officetrax_photos`))
      .then((response) => an('WorkOrder', response.data));
  },
  undoCompletion(id) {
    return axios.patch(adminPath(`/work_orders/${id}/undo_completion`)).then((response) => an('WorkOrder', response.data));
  },
  destroyBeforePhoto(id, photoId) {
    return axios.delete(adminPath(`/work_orders/${id}/before_photos/${photoId}`));
  },
  destroyAfterPhoto(id, photoId) {
    return axios.delete(adminPath(`/work_orders/${id}/after_photos/${photoId}`));
  },
  destroyNoShowPhoto(id, photoId) {
    return axios.delete(adminPath(`/work_orders/${id}/no_show_photos/${photoId}`));
  },
  getQRCodeWorkOrder(id) {
    return axios.get(adminPath(`/qr_codes/work_orders/${id}`)).then((response) => an('WorkOrder', response.data));
  },
  uploadQRCodeWorkOrder(id, data) {
    return fileFormDataSubmission(data, adminPath(`/qr_codes/work_orders/${id}/upload`));
  },
  getBeforePhotosZip(params) {
    return axios.get(adminPath(`/work_orders/${params.id}/before_photos.zip`), { responseType: 'blob' });
  },
  getAfterPhotosZip(params) {
    return axios.get(adminPath(`/work_orders/${params.id}/after_photos.zip`), { responseType: 'blob' });
  },
  getNoShowPhotosZip(params) {
    return axios.get(adminPath(`/work_orders/${params.id}/no_show_photos.zip`), { responseType: 'blob' });
  },
};
