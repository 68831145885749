<template>
  <b-form
    @submit.stop.prevent="handleSubmit"
    class="text-left"
  >
    <b-row>
      <b-col>
        <b-form-group
          :label="fieldLabels.service_id"
          label-for="quote-item-service_id"
        >
          <service-select
            name="quote-item-service_id"
            :value="form.service"
            :placeholder="quote.client.work_order_charges_require_service ? '' : 'Optional'"
            @input="onServiceChange"
            :client-id="quote.client_id"
            client-unassigned
            :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error, 'mb-1': true }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.service_id.$dirty"
            class="quote-item-service_id-feedback"
          >
            <span v-if="!$v.form.service_id.required">Please enter a Service.</span>
            <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service }}</span>
          </b-form-invalid-feedback>
          <b-form-textarea
            name="quote-item-description"
            v-model="$v.form.description.$model"
            placeholder="Enter a Description"
            :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
            rows="4"
          />
          <b-form-invalid-feedback
            v-if="$v.form.description.$dirty"
            class="quote-item-description-feedback"
          >
            <span v-if="!$v.form.description.required">Please enter a description.</span>
            <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group
          :label="fieldLabels.category"
          label-for="quote-item-category"
        >
          <category-select
            name="quote-item-category"
            :value="form.category"
            @input="(category) => form.category = category"
            :select-class="{ 'is-invalid': $v.form.category.$dirty && $v.form.category.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.category.$dirty"
            class="quote-item-category-feedback"
          >
            <span v-if="!$v.form.category.required">Please enter a category.</span>
            <span v-if="!$v.form.category.maxLength">
              The category must be less than {{ $v.form.category.$params.maxLength.max + 1 }} characters.
            </span>
            <span v-if="!$v.form.category.serverFailed">{{ serverErrors.category }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="isFexaQuote">
      <b-col>
        <b-form-group
          :label="fieldLabels.dollar_general_fexa_product_id"
          label-for="quote-item-dollar_general_fexa_product_id"
        >
          <dollar-general-fexa-product-select
            name="quote-item-dollar_general_fexa_product_id"
            :value="form.dollar_general_fexa_product_id"
            @input="onDollarGeneralFexaProductIdChange"
            :select-class="{ 'is-invalid': $v.form.dollar_general_fexa_product_id.$dirty && $v.form.dollar_general_fexa_product_id.$error }"
          />
          <b-form-invalid-feedback
            v-if="$v.form.dollar_general_fexa_product_id.$dirty"
            class="quote-item-dollar_general_fexa_product_id-feedback"
          >
            <span v-if="!$v.form.dollar_general_fexa_product_id.serverFailed">{{ serverErrors.dollar_general_fexa_product_id }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="4">
        <b-form-group
          :label="fieldLabels.quantity"
          label-for="work-order-charge-quantity"
          class="mb-0"
        >
          <b-form-input
            name="work-order-charge-quantity"
            v-model="$v.form.quantity.$model"
            :state="$v.form.quantity.$dirty ? !$v.form.quantity.$error : null"
            type="text"
          />
          <b-form-invalid-feedback
            v-if="$v.form.quantity.$dirty"
            class="work-order-charge-quantity-feedback"
          >
            <span v-if="!$v.form.quantity.required">Please enter a quantity.</span>
            <span v-if="!$v.form.quantity.decimal">Please enter a number.</span>
            <span v-if="!$v.form.quantity.serverFailed">{{ serverErrors.quantity }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="4">
        <b-form-group
          :label="fieldLabels.cost"
          label-for="work-order-charge-cost"
          class="mb-0"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.cost.$dirty && $v.form.cost.$error, 'has-warning': negativeProfitability }"
          >
            <b-form-input
              name="work-order-charge-cost"
              v-model="$v.form.cost.$model"
              :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
              type="text"
            />
          </b-input-group>
          <small
            v-if="!$v.form.cost.$error"
            class="form-text text-secondary"
          >
            <money-format
              class="d-inline"
              :value="totalCostCents"
              subunits-value
            />
            total
          </small>
          <b-form-invalid-feedback
            v-if="$v.form.cost.$dirty"
            :state="$v.form.cost.$dirty ? !$v.form.cost.$error : null"
            class="work-order-charge-cost-feedback"
          >
            <span v-if="!$v.form.cost.required">Please enter a cost.</span>
            <span v-if="!$v.form.cost.decimal">Please enter a number.</span>
            <span v-if="!$v.form.cost.serverFailed">{{ serverErrors.cost_cents }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col lg="4">
        <b-form-group
          :label="fieldLabels.list_cost"
          label-for="quote-item-list_cost"
          class="mb-0"
        >
          <template #label>
            {{ fieldLabels.list_cost }}
            <i
              class="far fa-question-circle"
              v-b-tooltip.html.hover
              title="The maximum the client agreed for us to pay for the item. <br /> Markup will be applied against the list cost."
            />
          </template>
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.list_cost.$dirty && $v.form.list_cost.$error }"
          >
            <b-form-input
              name="quote-item-list_cost"
              v-model="$v.form.list_cost.$model"
              :state="$v.form.list_cost.$dirty ? !$v.form.list_cost.$error : null"
              type="text"
              placeholder="Optional"
            />
          </b-input-group>

          <b-form-invalid-feedback
            v-if="$v.form.list_cost.$dirty"
            :state="$v.form.list_cost.$dirty ? !$v.form.list_cost.$error : null"
            class="quote-item-list_cost-feedback"
          >
            <span v-if="!$v.form.list_cost.decimal">Please enter a number.</span>
            <span v-if="!$v.form.list_cost.serverFailed">{{ serverErrors.list_cost_cents }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row v-if="negativeProfitability">
      <b-col>
        <small
          class="warning-feedback"
        >
          Warning: the {{ $store.getters.translate('work_orders.cost', 'Cost').toLowerCase() }} of this charge is more than the {{ $store.getters.translate('work_orders.price', 'Price').toLowerCase() }}.
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        lg="5"
      >
        <b-form-group
          label="Markup"
          label-for="quote-item-markup"
        >
          <b-input-group
            append="%"
            :class="{ 'is-invalid': $v.form.markup.$dirty && $v.form.markup.$error }"
          >
            <b-form-input
              name="quote-item-markup"
              v-model="$v.form.markup.$model"
              placeholder="Optional"
              :state="$v.form.markup.$dirty ? !$v.form.markup.$error : null"
              type="text"
            />
          </b-input-group>
          <small
            v-if="!$v.form.price.$error"
            class="form-text text-secondary"
          >
            Calculates the price automatically
          </small>
          <b-form-invalid-feedback
            v-if="$v.form.markup.$dirty"
            :state="$v.form.markup.$dirty ? !$v.form.markup.$error : null"
            class="quote-item-markup-feedback"
          >
            <span v-if="!$v.form.markup.decimal">Please enter a number.</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        lg="7"
      >
        <b-form-group
          :label="fieldLabels.price"
          label-for="quote-item-price"
          class="mb-0"
        >
          <b-input-group
            prepend="$"
            :class="{ 'is-invalid': $v.form.price.$dirty && $v.form.price.$error }"
          >
            <b-form-input
              name="quote-item-price"
              v-model="$v.form.price.$model"
              :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
              type="text"
            />
          </b-input-group>
          <small
            v-if="!$v.form.price.$error"
            class="form-text text-secondary"
          >
            <money-format
              class="d-inline"
              :value="totalPriceCents"
              subunits-value
            />
            total
          </small>
          <b-form-invalid-feedback
            v-if="$v.form.price.$dirty"
            :state="$v.form.price.$dirty ? !$v.form.price.$error : null"
            class="quote-item-price-feedback"
          >
            <span v-if="!$v.form.price.required">Please enter a price.</span>
            <span v-if="!$v.form.price.decimal">Please enter a number.</span>
            <span v-if="!$v.form.price.serverFailed">{{ serverErrors.price_cents }}</span>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <b-form-group label-for="quote-item-already_incurred">
          <b-form-checkbox
            name="quote-item-already_incurred"
            v-model="$v.form.already_incurred.$model"
          >
            The expense of this item has already been incurred
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-row>

    <hr class="mt-4">

    <b-row>
      <b-col
        offset-lg="6"
        lg="3"
      >
        <b-button
          variant="link-dark"
          block
          class="quote-item-close-button"
          @click="$emit('on-cancel')"
        >
          Cancel
        </b-button>
      </b-col>
      <b-col lg="3">
        <b-button
          class="quote-item-submit-button"
          block
          type="submit"
          variant="primary"
          :disabled="processing"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
import { required, requiredIf, decimal, maxLength } from 'vuelidate/lib/validators';
import formMixin from '@/mixins/formMixin';
import ServiceSelect from '@/components/admin/services/Select.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategoryButtonSelect.vue';
import DollarGeneralFexaProductSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemProductSelect.vue';
import { fromCents, toCents, roundedMoney } from '@/utils/money';

export default {
  name: 'QuoteItemsForm',
  components: {
    ServiceSelect,
    CategorySelect,
    DollarGeneralFexaProductSelect,
  },
  mixins: [formMixin],
  props: {
    quote: { type: Object, required: true },
    newForm: { type: Boolean, default: true },
    isFexaQuote: {  type: Boolean, default: false },
    description: String,
    category: String,
    quantity: Number,
    cost: Object,
    price: Object,
    list_cost: Object,
    dollar_general_fexa_product_id: [String, Number],
    service_id: [String, Number],
    service: Object,
    already_incurred: { type: Boolean, default: false },
  },
  data() {
    return {
      form: {
        description: this.description,
        category: this.category,
        quantity: this.quantity,
        cost: this.cost && this.cost.amount ? this.cost.amount / 100 : 0,
        cost_cents: this.cost && this.cost.amount ? this.cost.amount : 0,
        price: this.price && this.price.amount ? this.price.amount / 100 : 0,
        price_cents: this.price && this.price.amount ? this.price.amount : 0,
        list_cost: this.list_cost && this.list_cost.amount ? this.list_cost.amount / 100 : null,
        list_cost_cents: this.list_cost && this.list_cost.amount ? this.list_cost.amount : null,
        markup: null,
        service_id: this.service_id,
        dollar_general_fexa_product_id: this.dollar_general_fexa_product_id,
        service: this.service,
        already_incurred: !!this.already_incurred,
      },
      fieldLabels: {
        description: 'Description',
        category: 'Category',
        quantity: 'Quantity',
        cost: 'Cost',
        price: this.$store.getters.translate('work_orders.price', 'Price'),
        list_cost: this.$store.getters.translate('work_orders.list_cost', 'List Cost'),
        cost_cents: 'Cost',
        price_cents: this.$store.getters.translate('work_orders.price', 'Price'),
        list_cost_cents: this.$store.getters.translate('work_orders.list_cost', 'List Cost'),
        service_id: 'Service',
        dollar_general_fexa_product_id: 'Fexa Category',
        service: 'Service',
      },
    };
  },
  validations: {
    form: {
      description: {
        required,
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      category: {
        required,
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('category');
        },
      },
      quantity: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('quantity');
        },
      },
      cost: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('cost_cents');
        },
      },
      price: {
        required,
        decimal,
        serverFailed() {
          return !this.hasServerErrors('price_cents');
        },
      },
      list_cost: {
        decimal,
        serverFailed() {
          return !this.hasServerErrors('list_cost_cents');
        },
      },
      markup: {
        decimal,
      },
      service_id: {
        required: requiredIf(function () {
          return this.quote?.client?.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('service_id');
        },
      },
      dollar_general_fexa_product_id: {
        serverFailed() {
          return !this.hasServerErrors('dollar_general_fexa_product_id');
        },
      },
      already_incurred: {},
    },
  },
  computed: {
    negativeProfitability() {
      return this.form.cost_cents > this.form.price_cents;
    },
    totalCostCents() {
      return this.form.cost_cents * this.form.quantity;
    },
    totalPriceCents() {
      return this.form.price_cents * this.form.quantity;
    },
  },
  watch: {
    'form.cost': function (newCost) {
      this.form.cost_cents = toCents(newCost);
      this.updateCostChange();
    },
    'form.list_cost': function (newCost) {
      this.form.list_cost_cents = toCents(newCost);
      this.updateCostChange();
    },
    'form.price': function (newPrice) {
      this.form.price_cents = toCents(newPrice);
    },
    'form.markup': function (newMarkup) {
      this.form.markup = newMarkup;
      this.updateCostChange();
    },
  },
  methods: {
    updateCostChange() {
      const costInput = (this.form.list_cost_cents || 0) == 0 ? this.form.cost_cents : this.form.list_cost_cents;
      if (!isNaN(costInput) && (this.form.markup || 0) != 0) {
        const newPriceCents = costInput + (costInput * (this.form.markup / 100));
        this.form.price_cents = newPriceCents;
        this.form.price = roundedMoney(fromCents(newPriceCents));
      }
    },
    onServiceChange(service) {
      this.form.service_id = service ? service.id : null;
      this.form.service = service;
      if (service && this.newForm) {
        this.form.description = service.description;
      }
    },
    onDollarGeneralFexaProductIdChange(product) {
      this.form.dollar_general_fexa_product_id = product ? product.id : null;
    },
  },
};
</script>
