<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Item"
    @on-hide="resetFormData"
  >
    <quote-item-form
      v-bind="value"
      :quote="quote"
      @on-submit="updateItem"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :new-form="false"
      :is-fexa-quote="isFexaQuote"
    />
  </sfs-form-modal>
</template>

<script>
import QuoteItemForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'QuoteItemEditModal',
  mixins: [formModalMixin],
  components: {
    QuoteItemForm,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    quote: {
      type: Object,
      required: true,
    },
    isFexaQuote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: `quote-item-${this.value.id}-edit-modal`,
    };
  },
  methods: {
    updateItem(data, handleUpdate) {
      handleUpdate(this.$quotesAPI.updateItem(this.value.id, data));
    },
    onSave(item) {
      this.closeModal();
      this.resetFormData();
      this.$emit('on-save', item);
    },
  },
};
</script>
