<template>
  <sfs-base-list-filter
    title="Filter Quote Items"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :column-picker="false"
    :advanced-filter="false"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Description or Item Number"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import { QUOTE_ITEM_TABLE_COLUMN_OPTIONS, QUOTE_ITEM_TABLE_DEFAULT_COLUMNS, QUOTE_ITEM_TABLE_NAME, } from '@/constants/quoteItems';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'QuoteItemsListFilter',
  mixins: [listFilterMixin],
  props: {
    quote: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      availableColumns: QUOTE_ITEM_TABLE_COLUMN_OPTIONS,
      tableName: QUOTE_ITEM_TABLE_NAME,
    };
  },
  mounted() {
    this.applyFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.displayedColumns = QUOTE_ITEM_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    refreshTable() {
      this.$emit('data-changed');
    },
  },
};
</script>
