<template>
  <div>
    <b-row
      v-if="hasPinnedNotes"
      class="mb-3"
    >
      <b-col>
        <h4>
          Pinned
        </h4>
      </b-col>
    </b-row>
    <b-row
      v-if="hasPinnedNotes"
      class="mb-4"
    >
      <b-col>
        <timeline-item
          v-for="(note, index) in pinnedNotes"
          :key="note.id"
          :value="note"
          :timeline-trail="index !== 0"
          @on-destroy="() => handleNoteDestroyed(note, true)"
          @on-unpinned="(updatedNote) => handleNoteUnpinned(updatedNote)"
          :show-reference="referenceType !== note.reference_type || referenceId !== note.reference_id"
        />
      </b-col>
    </b-row>

    <b-row
      class="mb-3"
    >
      <b-col>
        <h4>
          Timeline
        </h4>
      </b-col>
    </b-row>

    <b-row :class="{ 'mb-4': notes.length > 0 }">
      <b-col>
        <timeline-form
          :create-func="createFunc"
          :reference-id="referenceId"
          :has-notes="notes.length > 0"
          @on-save="handleNoteAdded"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <timeline-item
          v-for="(note, index) in notes"
          :key="note.id"
          :value="note"
          :timeline-trail="index !== 0"
          @on-destroy="() => handleNoteDestroyed(note)"
          @on-pinned="(updatedNote) => handleNotePinned(updatedNote)"
          :show-reference="referenceType !== note.reference_type || referenceId !== note.reference_id"
        />
      </b-col>
    </b-row>

    <b-row
      v-if="hasMore"
      class="mt-3"
    >
      <b-col>
        <b-button
          variant="link"
          class="font-w500"
          @click="handleShowMore"
        >
          Show More
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { isEqual as _isEqual, isUndefined as _isUndefined, orderBy as _orderBy } from 'lodash';
import paginationMixin from '@/mixins/paginationMixin';
import TimelineItem from '@/components/admin/notes/TimelineItem.vue';
import TimelineForm from '@/components/admin/notes/TimelineForm.vue';

export default {
  name: 'NotesTimeline',
  mixins: [paginationMixin],
  components: {
    TimelineItem,
    TimelineForm,
  },
  props: {
    referenceId: {
      type: Number,
      required: true,
    },
    referenceType: {
      type: String,
      required: true,
    },
    listFunc: {
      type: Function,
      required: true,
    },
    listParams: {
      type: Object,
      default: () => new Object(),
    },
    createFunc: {
      type: Function,
      required: true,
    },
    appendToQuery: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      loaded: false,
      isLoadingData: false,
      pagination: {
        currentPage: 1,
        perPage: 5,
      },
      totalRows: 0,
      notes: [],
      pinnedNotes: [],
    };
  },
  methods: {
    updateTotalRows(newTotal) {
      this.totalRows = newTotal;
      this.$emit('total-rows-changed', newTotal + this.pinnedNotes.length);
    },
    handleShowMore() {
      this.pagination = { ...this.pagination, currentPage: this.pagination.currentPage + 1 };
    },
    handleNoteAdded(newNote) {
      this.notes = [newNote, ...this.notes];
      this.updateTotalRows(this.totalRows + 1);
    },
    handleNoteDestroyed(note, pinned = false) {
      const noteIndex = (pinned ? this.pinnedNotes : this.notes).findIndex((currentNote) => currentNote.id === note.id);
      if (noteIndex !== -1) {
        (pinned ? this.pinnedNotes : this.notes).splice(noteIndex, 1);
        if (pinned) {
            this.updateTotalRows(this.totalRows);
        } else {
          this.updateTotalRows(this.totalRows - 1);
        }
      }
    },
    handleNotePinned(note) {
      const noteIndex = this.notes.findIndex((currentNote) => currentNote.id === note.id);
      if (noteIndex !== -1) {
        this.notes.splice(noteIndex, 1);
        this.pinnedNotes = _orderBy([note, ...this.pinnedNotes], ['created_at'], ['desc']);
        this.updateTotalRows(this.totalRows - 1);
      }
    },
    handleNoteUnpinned(note) {
      const noteIndex = this.pinnedNotes.findIndex((currentNote) => currentNote.id === note.id);
      if (noteIndex !== -1) {
        this.pinnedNotes.splice(noteIndex, 1);
        this.notes = [..._orderBy([note, ...this.notes], ['created_at'], ['desc'])];
        this.updateTotalRows(this.totalRows + 1);
      }
    },
    getNotes() {
      this.listFunc(this.referenceId, {
        ...this.listParams,
        page: this.pagination.currentPage,
        per_page: this.pagination.perPage,
        pinned: false,
      })
        .then((response) => {
          const { total, notes } = response;
          this.updateTotalRows(total);
          this.notes = [...this.notes, ...notes];
        })
        .finally(() => {
          this.loaded = true;
          this.isLoadingData = false;
        });
    },
    getPinnedNotes() {
      this.listFunc(this.referenceId, {
        ...this.listParams,
        paginate: false,
        pinned: true,
      })
        .then((response) => {
          const { notes } = response;
          this.pinnedNotes = notes;
        });
    },
  },
  computed: {
    hasMore() {
      return this.notes.length < this.totalRows;
    },
    hasPinnedNotes() {
      return this.pinnedNotes.length > 0;
    },
  },
  watch: {
    'pagination.currentPage': function (newPage, oldPage) {
      if (!_isEqual(newPage, oldPage) && !_isUndefined(oldPage)) {
        this.getNotes();
      }
    },
  },
  mounted() {
    this.getPinnedNotes();
    this.getNotes();
  },
};
</script>
