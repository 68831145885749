<template>
  <sfs-form-modal
    :id="modalId"
    title="Edit Quote"
    @on-hide="resetFormData"
  >
    <quotes-form
      v-bind="value"
      @on-submit="updateQuote"
      @on-save="onSave"
      @on-cancel="closeModal"
      :key="formKey"
      :is-fexa-quote="isFexaQuote"
    />
  </sfs-form-modal>
</template>

<script>
import QuotesForm from './Form.vue';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'QuotesEditModal',
  mixins: [formModalMixin],
  components: {
    QuotesForm,
  },
  props: {
    value: Object,
    isFexaQuote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      modalId: 'quote-edit-modal',
    };
  },
  methods: {
    updateQuote(data, handleUpdate) {
      handleUpdate(this.$quotesAPI.update(this.value.id, data));
    },
    onSave(quote) {
      this.closeModal();
      this.$emit('input', quote);
    },
  },
};
</script>
