<template>
  <sfs-base-list-filter
    title="Filter Invoices"
    :table-name="tableName"
    :applied-filters="appliedFilters"
    :displayed-columns="displayedColumns"
    :available-columns="availableColumns"
    @filters-applied="onFiltersApplied"
    @filters-reset="onDefaultPresetApplied"
    @filter-cleared="onFilterCleared"
    :advanced-filter="false"
    :popover-filter="true"
  >
    <template #quick-filters>
      <b-form-group
        label="Search fields for Data"
        label-for="quick-filter-text"
        label-sr-only
      >
        <b-input-group>
          <b-form-input
            id="quick-filter-text"
            v-model="filters.text"
            type="text"
            placeholder="Filter by Number, Client Name or Code"
            @keydown.enter.native="applyFilters"
          />
          <template #append>
            <b-button
              id="quick-filter-apply-button"
              @click="applyFilters"
              variant="primary"
            >
              <i class="fa fa-search" />
            </b-button>
          </template>
        </b-input-group>
      </b-form-group>
    </template>

    <template #popover-filters>
      <b-row>
        <b-col>
          <b-form-group
            label="Client"
            label-for="popover-filter-client_id"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <client-select
              id="popover-filter-client_id"
              placeholder="Client"
              :value="client"
              @input="onClientChange"
              :persist-state="true"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            class="mb-2"
            label="Invoiced From"
            label-for="popover-filter-date_from"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-picker
              id="popover-filter-date_from"
              v-model="filters.date_from"
              placeholder="Invoiced From"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            label="Invoiced To"
            label-for="popover-filter-date_to"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <sfs-date-picker
              id="popover-filter-date_to"
              v-model="filters.date_to"
              placeholder="Invoiced To"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Invoice Delivery Method"
            label-for="popover-filter-invoice_delivery_method"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <invoice-delivery-method-select
              id="advanced-filter-invoice_delivery_method"
              placeholder="All Methods"
              v-model="filters.invoice_delivery_method"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <hr class="mt-0 mb-4">

      <b-row>
        <b-col>
          <b-form-group
            label="Requires Physical Invoice"
            label-for="popover-filter-physical_invoice"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-physical_invoice"
              v-model="filters.send_physical_invoice"
              :options="physicalInvoiceOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Finalized or Not"
            label-for="popover-filter-finalized"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-finalized"
              v-model="filters.finalized"
              :options="finalizedOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Uploaded or Not"
            label-for="popover-filter-uploaded"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-uploaded"
              v-model="filters.uploaded"
              :options="uploadedOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Emailed or Not"
            label-for="popover-filter-emailed"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-emailed"
              v-model="filters.emailed"
              :options="emailedOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Will Automatically Email"
            label-for="popover-filter-emailable"
            label-cols-lg="5"
            content-cols-lg="7"
          >
            <b-form-radio-group
              id="popover-filter-emailable"
              v-model="filters.emailable"
              :options="emailableOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </template>
  </sfs-base-list-filter>
</template>
<script>
import ClientSelect from '@/components/admin/clients/Select.vue';
import InvoiceDeliveryMethodSelect from '@/components/admin/clients/InvoiceDeliveryMethodSelect.vue';

import { INVOICE_TABLE_COLUMN_OPTIONS, INVOICE_TABLE_DEFAULT_COLUMNS, INVOICE_TABLE_NAME, INVOICE_TABLE_PHYSICAL_INVOICE_FILTER_OPTIONS, INVOICE_TABLE_EMAILED_FILTER_OPTIONS, INVOICE_TABLE_EMAILABLE_FILTER_OPTIONS, INVOICE_TABLE_FINALIZED_FILTER_OPTIONS, INVOICE_TABLE_UPLOADED_FILTER_OPTIONS } from '@/constants/invoices';
import listFilterMixin from '@/mixins/listFilterMixin';

export default {
  name: 'InvoicesListFilter',
  components: {
    ClientSelect,
    InvoiceDeliveryMethodSelect,
  },
  mixins: [listFilterMixin],
  data() {
    return {
      availableColumns: INVOICE_TABLE_COLUMN_OPTIONS,
      tableName: INVOICE_TABLE_NAME,
      physicalInvoiceOptions: INVOICE_TABLE_PHYSICAL_INVOICE_FILTER_OPTIONS,
      finalizedOptions: INVOICE_TABLE_FINALIZED_FILTER_OPTIONS,
      uploadedOptions: INVOICE_TABLE_UPLOADED_FILTER_OPTIONS,
      emailedOptions: INVOICE_TABLE_EMAILED_FILTER_OPTIONS,
      emailableOptions: INVOICE_TABLE_EMAILABLE_FILTER_OPTIONS,
    };
  },
  mounted() {
    this.loadInitialFilters();
  },
  methods: {
    onDefaultPresetApplied() {
      this.filters = {};
      this.client = null;
      this.displayedColumns = INVOICE_TABLE_DEFAULT_COLUMNS;
      this.applyFilters();
    },
    onClientChange(client) {
      this.filters.client_id = client ? client.id.toString() : '';
      this.client = client;
    },
    loadInitialFilters() {
      if (this.initialFilters.client_id) {
        this.$clientsAPI.get(this.initialFilters.client_id).then((client) => {
          this.client = client;
          this.applyFilters();
        });
      } else {
        this.applyFilters();
      }
    },
  },
};
</script>

