export default {
  methods: {
    isApproved(technicianPayment) {
      return technicianPayment.approved_at ? true : false;
    },
    isFinalized(technicianPayment) {
      return technicianPayment.finalized_at ? true : false;
    },
    statusVariant(technicianPayment) {
      return this.isFinalized(technicianPayment) ? 'purple' : (this.isApproved(technicianPayment) ? 'success' : 'secondary');
    },
    status(technicianPayment) {
      return this.isFinalized(technicianPayment) ? 'Finalized' : (this.isApproved(technicianPayment) ? 'Approved' : 'Not Approved');
    },
  },
};
