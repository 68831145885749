<template>
  <sfs-form-modal
    :id="modalId"
    class="text-left"
    :title="`Propose Quote ${value.number}`"
  >
    <b-form
      novalidate
      @submit.stop.prevent="handleSubmit"
    >
      <b-row>
        <b-col>
          <b-alert
            :show="hasBaseServerErrors()"
            variant="danger"
            dismissible
          >
            <p
              class="mb-0"
              v-for="(error, index) in baseServerErrors"
              :key="index"
            >
              {{ error }}
            </p>
          </b-alert>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group
            label="Send Emails"
            label-for="quote-propose-send-emails"
            label-sr-only
          >
            <b-form-radio-group
              name="quote-propose-send-emails"
              v-model="sendEmail"
              :options="sendEmailOptions"
              stacked
            />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="sendEmail">
        <b-row
          v-for="(v, index) in $v.form.emails.$each.$iter"
          :key="index"
          class="mb-3"
        >
          <b-col
            cols="11"
          >
            <b-form-input
              name="quote-propose-email"
              v-model.trim="v.email.$model"
              :state="!v.email.$error"
              autocomplete="new-password"
              type="text"
              placeholder="example@email.com"
            />
            <small
              v-if="index == 0"
              class="form-text text-muted"
            >
              Enter one or more emails to send the Quote to
            </small>
            <b-form-invalid-feedback
              v-if="v.email.$dirty"
              class="quote-propose-email-feedback"
            >
              <span v-if="!v.email.required">Please enter an email. </span>
              <span v-if="!v.email.email">Please enter a valid email.</span>
              <span v-if="!v.email.serverFailed">{{ serverErrors.email }}</span>
            </b-form-invalid-feedback>
          </b-col>
          <b-col
            cols="1"
            class="pl-0"
          >
            <b-button
              @click="removeEmail(index)"
              :disabled="index == 0"
              variant="link"
            >
              <i class="far fa-trash-can" />
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-row v-if="sendEmail">
        <b-col>
          <b-button
            class="quote-propose-add-button"
            variant="outline-secondary"
            @click="addEmail"
          >
            <span> <i class="fa fa-plus" /> Add Email</span>
          </b-button>
        </b-col>
      </b-row>

      <hr class="mt-4">

      <b-row>
        <b-col
          offset-lg="6"
          lg="3"
        >
          <b-button
            variant="link-dark"
            block
            class="quote-propose-close-button"
            @click="closeModal"
          >
            Cancel
          </b-button>
        </b-col>
        <b-col lg="3">
          <b-button
            class="quote-propose-submit-button"
            block
            type="submit"
            variant="primary"
          >
            Propose
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </sfs-form-modal>
</template>

<script>
import { requiredIf, email } from 'vuelidate/lib/validators';
import { isNil as _isNil } from 'lodash';
import formMixin from '@/mixins/formMixin';
import formModalMixin from '@/mixins/formModalMixin';

export default {
  name: 'QuotesProposeModal',
  mixins: [formMixin, formModalMixin],
  props: {
    value: Object,
    email: String,
  },
  data() {
    return {
      sendEmail: false,
      sendEmailOptions: [
        { text: 'Propose the Quote only', value: false },
        { text: 'Propose & email the Quote', value: true },
      ],
      form: {
        emails: [{ email: this.email }],
      },
      fieldLabels: {
        email: 'Email',
      },
      modalId: `quote-${this.value.id}-propose-modal`,
    };
  },
  validations: {
    form: {
      emails: {
        $each: {
          email: {
            required: requiredIf(function () {
              return this.sendEmail;
            }),
            email,
            serverFailed() {
              return !this.hasServerErrors('emails');
            }
          }
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      const emails = this.form.emails.map(item => item.email).filter(item => !_isNil(item));
      this.$quotesAPI
        .propose(this.value.id, { emails: emails })
        .then((quote) => {
          this.resetServerErrors();
          this.$v.$reset();
          this.$emit('input', quote);
          this.closeModal();
          this.form.emails = [{ email: null }];
        })
        .catch((error) => {
          this.processServerErrors(error.response.data.errors);
        });
    },
    addEmail() {
      this.form.emails.push({ email: null });
    },
    removeEmail(index) {
      this.form.emails.splice(index, 1);
    },
  },
};
</script>
