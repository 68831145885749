<template>
  <div>
    <base-page-heading
      title="New Quote"
      show-back-button
    />
    <div
      class="content"
      v-if="loaded"
    >
      <b-row class="pt-3">
        <b-col>
          <b-form
            novalidate
            @submit.stop.prevent="handleSubmit"
          >
            <b-row
              v-if="workOrder"
              class="mb-3"
            >
              <b-col>
                <b-alert
                  show
                  variant="primary"
                  class="mb-0"
                >
                  <div class="text-prewrap">
                    You are preparing a Quote for Work Order
                    <b-link :to="{ name: 'work-order', params: { id: workOrder.id } }">
                      {{ workOrder.number }}.
                    </b-link>
                  </div>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-alert
                  :show="hasBaseServerErrors()"
                  variant="danger"
                  dismissible
                >
                  <p
                    class="mb-0"
                    v-for="(error, index) in baseServerErrors"
                    :key="index"
                  >
                    {{ error }}
                  </p>
                </b-alert>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.client_id"
                      label-for="quote-client_id"
                    >
                      <client-select
                        id="quote-client_id"
                        :select-class="{ 'is-invalid': $v.form.client_id.$dirty && $v.form.client_id.$error, 'mb-1': true }"
                        :value="form.client"
                        @input="onClientChange"
                        :placeholder="`Select a ${$store.getters.translate('client', 'Client')}`"
                        :disabled="linkedToWorkOrder"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.client_id.$dirty"
                        id="quote-client_id-feedback"
                      >
                        <span v-if="!$v.form.client_id.required">Please enter a {{ $store.getters.translate('client', 'client') }}.</span>
                        <span v-if="!$v.form.client_id.serverFailed">
                          {{ serverErrors.client }}
                        </span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.location_id"
                      label-for="quote-location_id"
                    >
                      <location-select
                        id="quote-location_id"
                        :client-id="form.client_id"
                        :select-class="{ 'is-invalid': $v.form.location_id.$dirty && $v.form.location_id.$error }"
                        :value="form.location"
                        @input="onLocationChange"
                        :disabled="linkedToWorkOrder"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.location_id.$dirty"
                        id="quote-location_id-feedback"
                      >
                        <span v-if="!$v.form.location_id.required">Please enter a location.</span>
                        <span v-if="!$v.form.location_id.serverFailed">{{ serverErrors.location }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.date"
                      label-for="quote-date"
                    >
                      <sfs-date-picker
                        id="quote-date"
                        v-model="form.date"
                        :class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                        :with-class="{ 'is-invalid': $v.form.date.$dirty && $v.form.date.$error }"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.date.$dirty"
                        id="quote-date-feedback"
                      >
                        <span v-if="!$v.form.date.required">Please enter a date.</span>
                        <span v-if="!$v.form.date.serverFailed">{{ serverErrors.date }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12">
                    <b-form-group
                      :label="fieldLabels.due_date"
                      label-for="quote-due_date"
                    >
                      <sfs-date-picker
                        id="quote-due_date"
                        v-model="form.due_date"

                        :class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
                        :with-class="{ 'is-invalid': $v.form.due_date.$dirty && $v.form.due_date.$error }"
                        placeholder="Optional"
                      />
                      <b-form-invalid-feedback
                        v-if="$v.form.due_date.$dirty"
                        id="quote-due_date-feedback"
                      >
                        <span v-if="!$v.form.due_date.serverFailed">{{ serverErrors.due_date }}</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  label="Details"
                  label-for="quote-description"
                >
                  <service-select
                    :select-class="{ 'is-invalid': $v.form.service_id.$dirty && $v.form.service_id.$error, 'mb-1': true }"
                    id="work-order-service_id"
                    placeholder="Select a Service"
                    :client-id="form.client_id"
                    client-unassigned
                    :value="form.service"
                    @input="onServiceChange"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.service_id.$dirty"
                    id="work-order-service_id-feedback"
                  >
                    <span v-if="!$v.form.service_id.required">Please enter a service.</span>
                    <span v-if="!$v.form.service_id.serverFailed">{{ serverErrors.service }}</span>
                  </b-form-invalid-feedback>

                  <b-form-textarea
                    id="quote-description"
                    v-model="$v.form.description.$model"
                    :state="$v.form.description.$dirty ? !$v.form.description.$error : null"
                    rows="3"
                    placeholder="Enter a description"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.description.$dirty"
                    id="quote-description-feedback"
                  >
                    <span v-if="!$v.form.description.serverFailed">{{ serverErrors.description }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                lg="6"
              >
                <b-form-group
                  :label="fieldLabels.number"
                  label-for="quote-number"
                >
                  <b-form-input
                    id="quote-number"
                    v-model="form.number"
                    :state="$v.form.number.$dirty ? !$v.form.number.$error : null"
                    placeholder="Optional"
                    type="text"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.number.$dirty"
                    id="quote-number-feedback"
                  >
                    <span v-if="!$v.form.number.maxLength">
                      The number must be less than {{ $v.form.number.$params.maxLength.max + 1 }} characters.
                    </span>
                    <span v-if="!$v.form.number.serverFailed">{{ serverErrors.number }}</span>
                  </b-form-invalid-feedback>
                  <small class="form-text text-muted">If left blank the number will be automatically generated</small>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <b-form-group label-for="quote-capital_expenditure">
                  <b-form-checkbox
                    switch
                    id="quote-capital_expenditure"
                    v-model="$v.form.capital_expenditure.$model"
                  >
                    This Quote is a capital expenditure
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row
              v-if="isFexaQuote"
              class="mt-3"
            >
              <b-col>
                <b-form-group
                  :label="fieldLabels.dollar_general_fexa_capex_description"
                  label-for="quote-dollar_general_fexa_capex_description"
                >
                  <dollar-general-fexa-capex-description-select
                    name="quote-dollar_general_fexa_capex_description"
                    :value="$v.form.dollar_general_fexa_capex_description.$model"
                    @input="onDollarGeneralFexaCapexDescriptionChange"
                    :select-class="{ 'is-invalid': $v.form.dollar_general_fexa_capex_description.$dirty && $v.form.dollar_general_fexa_capex_description.$error }"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.dollar_general_fexa_capex_description.$dirty"
                    class="quote-dollar_general_fexa_capex_description-feedback"
                  >
                    <span v-if="!$v.form.dollar_general_fexa_capex_description.serverFailed">{{ serverErrors.dollar_general_fexa_capex_description }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group
                  :label="fieldLabels.dollar_general_fexa_activity_code"
                  label-for="quote-dollar_general_fexa_activity_code"
                >
                  <dollar-general-fexa-activity-code-select
                    name="quote-dollar_general_fexa_activity_code"
                    :value="$v.form.dollar_general_fexa_activity_code.$model"
                    @input="onDollarGeneralFexaActivityCodeChange"
                    :select-class="{ 'is-invalid': $v.form.dollar_general_fexa_activity_code.$dirty && $v.form.dollar_general_fexa_activity_code.$error }"
                  />
                  <b-form-invalid-feedback
                    v-if="$v.form.dollar_general_fexa_activity_code.$dirty"
                    class="quote-dollar_general_fexa_activity_code-feedback"
                  >
                    <span v-if="!$v.form.dollar_general_fexa_activity_code.serverFailed">{{ serverErrors.dollar_general_fexa_activity_code }}</span>
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <h3 class="mt-4 mb-3">
                  Items
                </h3>
              </b-col>
            </b-row>

            <div
              v-for="(v, index) in $v.form.items.$each.$iter"
              class="mt-3"
              :key="index"
            >
              <b-row>
                <b-col
                  lg="6"
                >
                  <b-row>
                    <b-col>
                      <b-form-group
                        :label="fieldLabels.service_id"
                        label-for="quote-item-service_id"
                        class="mb-0"
                      >
                        <service-select
                          :select-class="{ 'is-invalid': v.service_id.$dirty && v.service_id.$error }"
                          class="quote-item-service_id mb-1"
                          :placeholder="form.client?.work_order_charges_require_service ? '' : 'Optional'"
                          :client-id="form.client_id"
                          client-unassigned
                          v-model="v.service.$model"
                          @input="(service) => onChargeServiceChange(index, service)"
                        />
                        <b-form-invalid-feedback
                          v-if="v.service_id.$dirty"
                          class="quote-item-service_id-feedback"
                        >
                          <span v-if="!v.service_id.required">Please enter a service.</span>
                          <span v-if="!v.service_id.serverFailed">{{ serverErrors.service }}</span>
                        </b-form-invalid-feedback>
                        <b-form-textarea
                          class="quote-item-description"
                          placeholder="Enter a description"
                          v-model="v.description.$model"
                          :state="v.description.$dirty ? !v.description.$error : null"
                          rows="4"
                        />
                        <b-form-invalid-feedback
                          v-if="v.description.$dirty"
                          class="quote-item-description-feedback"
                        >
                          <span v-if="!v.description.required">Please enter a description.</span>
                          <span v-if="!v.description.serverFailed">{{ serverErrors.description }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    v-if="isFexaQuote"
                    class="mt-3"
                  >
                    <b-col>
                      <b-form-group
                        :label="fieldLabels.dollar_general_fexa_product_id"
                        label-for="quote-dollar_general_fexa_product_id"
                      >
                        <dollar-general-fexa-product-select
                          name="quote-dollar_general_fexa_product_id"
                          :value="v.dollar_general_fexa_product_id.$model"
                          @input="(product) => onItemDollarGeneralFexaProductIdChange(index, product)"
                          :select-class="{ 'is-invalid': v.dollar_general_fexa_product_id.$dirty && v.dollar_general_fexa_product_id.$error }"
                        />
                        <b-form-invalid-feedback
                          v-if="v.dollar_general_fexa_product_id.$dirty"
                          class="quote-dollar_general_fexa_product_id-feedback"
                        >
                          <span v-if="!v.dollar_general_fexa_product_id.serverFailed">{{ serverErrors.dollar_general_fexa_product_id }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col lg="6">
                  <b-row>
                    <b-col>
                      <b-form-group
                        :label="fieldLabels.category"
                        label-for="quote-category"
                      >
                        <category-select
                          name="quote-category"
                          v-model="v.category.$model"
                          :select-class="{ 'is-invalid': v.category.$dirty && v.category.$error }"
                        />
                        <b-form-invalid-feedback
                          v-if="v.category.$dirty"
                          class="quote-category-feedback"
                        >
                          <span v-if="!v.category.required">Please enter a category.</span>
                          <span v-if="!v.category.maxLength">
                            The category must be less than {{ v.category.$params.maxLength.max + 1 }} characters.
                          </span>
                          <span v-if="!v.category.serverFailed">{{ serverErrors.category }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                    <b-col class="text-right">
                      <b-button
                        class="remove-item-button font-size-lg"
                        v-b-tooltip.hover
                        title="Remove the Charge"
                        variant="link"
                        @click="() => removeItem(index)"
                      >
                        <i class="fa fa-close" />
                      </b-button>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col lg="4">
                      <b-form-group
                        :label="fieldLabels.quantity"
                        label-for="quote-quantity"
                        class="mb-0"
                      >
                        <b-form-input
                          name="quote-quantity"
                          v-model="v.quantity.$model"
                          :state="v.quantity.$dirty ? !v.quantity.$error : null"
                          type="text"
                        />
                        <b-form-invalid-feedback
                          v-if="v.quantity.$dirty"
                          class="quote-quantity-feedback"
                        >
                          <span v-if="!v.quantity.required">Please enter a quantity.</span>
                          <span v-if="!v.quantity.decimal">Please enter a number.</span>
                          <span v-if="!v.quantity.serverFailed">{{ serverErrors.quantity }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4">
                      <b-form-group
                        :label="fieldLabels.cost"
                        label-for="quote-cost"
                        class="mb-0"
                      >
                        <b-input-group
                          prepend="$"
                          :class="{ 'is-invalid': v.cost.$dirty && v.cost.$error, 'has-warning': !v.cost.$error && negativeProfitability(v) }"
                        >
                          <b-form-input
                            name="quote-cost"
                            @input="(newCost) => onItemCostChange(v, newCost)"
                            :state="v.cost.$dirty ? !v.cost.$error : null"
                            type="text"
                          />
                        </b-input-group>
                        <small
                          v-if="!v.cost.$error"
                          class="form-text text-secondary"
                        >
                          <money-format
                            class="d-inline"
                            :value="toCents(v.cost.$model) * v.quantity.$model"
                            subunits-value
                          />
                          total
                        </small>
                        <b-form-invalid-feedback
                          v-if="v.cost.$dirty"
                          class="quote-cost-feedback"
                        >
                          <span v-if="!v.cost.required">Please enter a cost.</span>
                          <span v-if="!v.cost.decimal">Please enter a number.</span>
                          <span v-if="!v.cost.serverFailed">
                            {{ serverErrors.cost_cents }}
                          </span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col lg="4">
                      <b-form-group
                        :label="fieldLabels.list_cost"
                        label-for="quote-list_cost"
                        class="mb-0"
                      >
                        <template #label>
                          {{ fieldLabels.list_cost }}
                          <i
                            class="far fa-question-circle"
                            v-b-tooltip.html.hover
                            title="The maximum the client agreed for us to pay for the item. <br /> Markup will be applied against the list cost."
                          />
                        </template>
                        <b-input-group
                          prepend="$"
                          :class="{ 'is-invalid': v.list_cost.$dirty && v.list_cost.$error }"
                        >
                          <b-form-input
                            name="quote-list_cost"
                            @input="(newCost) => onItemListCostChange(v, newCost)"
                            :state="v.list_cost.$dirty ? !v.list_cost.$error : null"
                            type="text"
                            placeholder="Optional"
                          />
                        </b-input-group>

                        <b-form-invalid-feedback
                          v-if="v.list_cost.$dirty"
                          :state="v.list_cost.$dirty ? !v.list_cost.$error : null"
                          class="quote-item-list_cost-feedback"
                        >
                          <span v-if="!v.list_cost.decimal">Please enter a number.</span>
                          <span v-if="!v.list_cost.serverFailed">{{ serverErrors.list_cost_cents }}</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>

                  <b-row v-if="negativeProfitability(v)">
                    <b-col>
                      <small
                        class="warning-feedback"
                      >
                        Warning: the {{ $store.getters.translate('work_orders.cost', 'Cost') }} of this charge is more than the {{ $store.getters.translate('work_orders.price', 'Price') }}.
                      </small>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col
                      cols="12"
                      lg="5"
                    >
                      <b-form-group
                        label="Markup"
                        label-for="quote-markup"
                      >
                        <b-input-group
                          append="%"
                          :class="{ 'is-invalid': v.markup.$dirty && v.markup.$error }"
                        >
                          <b-form-input
                            name="quote-markup"
                            placeholder="Optional"
                            @input="(newMarkup) => onItemMarkupChange(v, newMarkup)"
                            :state="v.markup.$dirty ? !v.markup.$error : null"
                            type="text"
                          />
                        </b-input-group>
                        <b-form-invalid-feedback
                          v-if="v.markup.$dirty"
                          :state="v.markup.$dirty ? !v.markup.$error : null"
                          class="quote-markup-feedback"
                        >
                          <span v-if="!v.markup.decimal">Please enter a number.</span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>

                    <b-col
                      cols="12"
                      lg="7"
                    >
                      <b-form-group
                        :label="fieldLabels.price"
                        label-for="quote-price"
                        class="mb-0"
                      >
                        <b-input-group
                          prepend="$"
                          :class="{ 'is-invalid': v.price.$dirty && v.price.$error }"
                        >
                          <b-form-input
                            name="quote-price"
                            v-model="v.price.$model"
                            :state="v.price.$dirty ? !v.price.$error : null"
                            type="text"
                          />
                        </b-input-group>
                        <small
                          v-if="!v.price.$error"
                          class="form-text text-secondary"
                        >
                          <money-format
                            class="d-inline"
                            :value="toCents(v.price.$model) * v.quantity.$model"
                            subunits-value
                          />
                          total
                        </small>
                        <b-form-invalid-feedback
                          v-if="v.price.$dirty"
                          class="quote-price-feedback"
                        >
                          <span v-if="!v.price.required">Please enter a price.</span>
                          <span v-if="!v.price.decimal">Please enter a number.</span>
                          <span v-if="!v.price.serverFailed">
                            {{ serverErrors.price_cents }}
                          </span>
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col>
                <b-button
                  id="add-quote-item-button"
                  class="mt-1"
                  variant="outline-secondary"
                  @click="addItem"
                >
                  <i class="fa fa-plus mr-1" />Add Item
                </b-button>
              </b-col>
            </b-row>

            <b-row class="mb-3">
              <b-col
                offset-lg="8"
                lg="2"
              >
                <b-button
                  variant="link-dark"
                  block
                  id="quote-close-button"
                  @click="() => $router.go(-1)"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col lg="2">
                <b-button
                  id="quote-submit-button"
                  block
                  type="submit"
                  variant="primary"
                  :disabled="processing"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/formMixin';
import { required, maxLength, decimal, minLength, requiredIf } from 'vuelidate/lib/validators';
import { isNil as _isNil } from 'lodash';
import ClientSelect from '@/components/admin/clients/Select.vue';
import LocationSelect from '@/components/admin/locations/Select.vue';
import ServiceSelect from '@/components/admin/services/Select.vue';
import CategorySelect from '@/components/admin/workOrderCharges/CategoryButtonSelect.vue';
import DollarGeneralFexaProductSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemProductSelect.vue';
import DollarGeneralFexaCapexDescriptionSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemCapexDescriptionSelect.vue';
import DollarGeneralFexaActivityCodeSelect from '@/components/admin/integrationReferences/DollarGeneralFexa/QuoteItemActivityCodeSelect.vue';
import { QUOTES_FORM_LABELS } from '@/constants/quotes.js';
import { toCents, roundedMoney } from '@/utils/money';

export default {
  name: 'NewQuote',
  components: {
    ClientSelect,
    LocationSelect,
    ServiceSelect,
    CategorySelect,
    DollarGeneralFexaProductSelect,
    DollarGeneralFexaCapexDescriptionSelect,
    DollarGeneralFexaActivityCodeSelect,
  },
  mixins: [formMixin],
  props: {
    work_order_id: {
      type: [String, Number]
    },
  },
  data() {
    const costLabel = this.$store.getters.translate('work_orders.cost', 'Cost');
    const priceLabel = this.$store.getters.translate('work_orders.price', 'Price');
    const listCostLabel = this.$store.getters.translate('work_orders.list_cost', 'List Cost');
    return {
      workOrder: null,
      loaded: false,
      form: {
        number: null,
        description: null,
        date: new Date(),
        client_id: null,
        client: null,
        location_id: null,
        location: null,
        service_id: null,
        service: null,
        capital_expenditure: false,
        dollar_general_fexa_capex_description: null,
        dollar_general_fexa_activity_code: null,
        items: [{
          description: null,
          service_id: null,
          service: null,
          quantity: 1,
          cost: 0,
          markup: null,
          price: 0,
          list_cost: null,
          category: 'labor',
          dollar_general_fexa_product_id: null,
        }],
      },
      fieldLabels: {
        ...QUOTES_FORM_LABELS,
        client_id: this.$store.getters.translate('client', 'Client'),
        service_id: 'Service',
        category: 'Category',
        quantity: 'Quantity',
        cost: costLabel,
        list_cost: listCostLabel,
        price: priceLabel,
        cost_cents: costLabel,
        price_cents: priceLabel,
        list_cost_cents: listCostLabel,
      },
    };
  },
  validations: {
    form: {
      number: {
        maxLength: maxLength(255),
        serverFailed() {
          return !this.hasServerErrors('number');
        },
      },
      description: {
        serverFailed() {
          return !this.hasServerErrors('description');
        },
      },
      dollar_general_fexa_capex_description: {
        serverFailed() {
          return !this.hasServerErrors('dollar_general_fexa_capex_description');
        },
      },
      dollar_general_fexa_activity_code: {
        serverFailed() {
          return !this.hasServerErrors('dollar_general_fexa_activity_code');
        },
      },
      date: {
        required,
        serverFailed() {
          return !this.hasServerErrors('date');
        },
      },
      due_date: {
        serverFailed() {
          return !this.hasServerErrors('due_date');
        },
      },
      client_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('client');
        },
      },
      work_order_id: {
        serverFailed() {
          return !this.hasServerErrors('work_order');
        },
      },
      location_id: {
        required,
        serverFailed() {
          return !this.hasServerErrors('location');
        },
      },
      service_id: {
        required: requiredIf(function () {
          return this.form.client?.work_order_charges_require_service;
        }),
        serverFailed() {
          return !this.hasServerErrors('service');
        },
      },
      capital_expenditure: {},
      items: {
        required,
        minLength: minLength(1),
        $each: {
          description: {
            required,
            serverFailed() {
              return !this.hasServerErrors('description');
            },
          },
          service: {},
          service_id: {
            required: requiredIf(function () {
              return this.form.client?.work_order_charges_require_service;
            }),
            serverFailed() {
              return !this.hasServerErrors('service_id');
            },
          },
          dollar_general_fexa_product_id: {
            serverFailed() {
              return !this.hasServerErrors('dollar_general_fexa_product_id');
            },
          },
          category: {
            required,
            maxLength: maxLength(255),
            serverFailed() {
              return !this.hasServerErrors('category');
            },
          },
          quantity: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('quantity');
            },
          },
          markup: {
            decimal,
          },
          cost: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('cost');
            },
          },
          list_cost: {
            decimal,
            serverFailed() {
              return !this.hasServerErrors('list_cost_cents');
            },
          },
          price: {
            required,
            decimal,
            serverFailed() {
              return !this.hasServerErrors('price');
            },
          },
        },
      },
    },
  },
  mounted() {
    if (this.work_order_id) {
      this.loadWorkOrder();
    } else {
      this.loaded = true;
    }
  },
  computed: {
    linkedToWorkOrder() {
      return !_isNil(this.workOrder);
    },
    isFexaQuote() {
      return !!this.workOrder?.dollar_general_fexa_assignment_reference_id;
    }
  },
  methods: {
    handleSubmit() {
      if (this.hasFormErrors()) {
        return;
      }
      this.processing = true;
      this.$quotesAPI.create({
          ...this.form,
          items_attributes: this.form.items.map((item) => ({
            ...item,
            cost_cents: this.toCents(item.cost),
            list_cost_cents: this.toCents(item.list_cost),
            price_cents: this.toCents(item.price),
          })),
      }).then((data) => {
        this.resetServerErrors();
        this.$v.$reset();
        this.processing = false;
        this.$router.push({ name: 'quote', params: { id: data.id } });
      }).catch((error) => {
          this.processServerErrors(error.response.data.errors);
          this.processing = false;
      });
    },
    addItem() {
      const description = this.form.items.length === 0 ? this.form.description : '';
      const service = this.form.items.length === 0 ? this.form.service : null;
      this.form.items.push({
        description: description,
        service_id: service?.id,
        service: service,
        quantity: 1,
        cost: 0,
        price: 0,
        category: 'labor',
      });
    },
    loadWorkOrder() {
      this.$workOrdersAPI.get(this.work_order_id).then((workOrder) => {
        this.workOrder = workOrder;
        this.form.work_order_id = workOrder.id;
        this.form.client_id = workOrder.client_id;
        this.form.location_id = workOrder.location_id;
        this.form.service_id = workOrder.service_id;
        this.form.description = workOrder.description;
        this.form.client = workOrder.client;
        this.form.location = workOrder.location;
        this.form.service = workOrder.service;
        this.form.items[0].service = workOrder.service;
        this.form.items[0].service_id = workOrder.service_id;
        this.form.items[0].description = workOrder.description;
      })
      .finally(() => {
        this.loaded = true;
      });
    },
    negativeProfitability(v) {
      return this.toCents(v.cost.$model) > this.toCents(v.price.$model);
    },
    toCents(amount) {
      return toCents(amount);
    },
    onItemMarkupChange(v, newMarkup) {
      const markup = isNaN(newMarkup) ? 0 : parseFloat(newMarkup / 100);
      v.markup.$model = markup * 100;
      this.updateCostChange(v);
    },
    onItemCostChange(v, newCost) {
      v.cost.$model = isNaN(newCost) ? 0 :Number.parseFloat(newCost);
      this.updateCostChange(v);
    },
    onItemListCostChange(v, newListCost) {
      v.list_cost.$model = isNaN(newListCost) ? null : Number.parseFloat(newListCost);
      this.updateCostChange(v);
    },
    updateCostChange(v) {
      const costInput = (v.list_cost.$model || 0) == 0 ? v.cost.$model : v.list_cost.$model;
      if (!isNaN(costInput) && (v.markup.$model || 0) != 0) {
        const newPriceCents = costInput + (costInput * (v.markup.$model / 100));
        v.price.$model = roundedMoney(newPriceCents);
      }
    },
    removeItem(index) {
      this.form.items.splice(index, 1);
    },
    onClientChange(client) {
      this.form.client_id = client ? client.id : null;
      this.form.client = client;
      this.form.location_id = null;
      this.form.location = null;
    },
    onLocationChange(location) {
      this.form.location_id = location ? location.id : null;
      this.form.location = location;
    },
    onServiceChange(service) {
      this.form.service_id = service ? service.id : null;
      this.form.service = service;
      if (service) {
        this.form.description = service.description;
      }
    },
    onChargeServiceChange(index, service) {
      this.form.items[index].service_id = service ? service.id : null;
      this.form.items[index].service = service;

      if (service) {
        this.form.items[index].description = service.description;
      }
    },
    onItemDollarGeneralFexaProductIdChange(index, product) {
      this.form.items[index].dollar_general_fexa_product_id = product ? product.id : null;
    },
    onDollarGeneralFexaCapexDescriptionChange(description) {
      this.form.dollar_general_fexa_capex_description = description ? description.id : null;
    },
    onDollarGeneralFexaActivityCodeChange(code) {
      this.form.dollar_general_fexa_activity_code = code ? code.id : null;
    },
  },
};
</script>
