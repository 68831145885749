<template>
  <div
    id="base-list-filter"
    @keydown.enter="applyFilters"
  >
    <b-form @submit.prevent>
      <b-row no-gutters>
        <b-col
          cols="12"
          md="8"
          lg="8"
          xl="5"
        >
          <!-- @slot Content in this slot will be inline with the button to open the filter modal. -->
          <slot name="quick-filters" />
        </b-col>

        <b-col
          cols="auto"
          class="ml-2 mr-auto"
        >
          <b-button
            v-if="advancedFilter"
            id="advanced-filter-button"
            v-b-modal.advanced-filter-modal
            v-b-tooltip.hover
            class="text-primary"
            variant="link"
            :title="title"
          >
            <i class="fas fa-sliders-h mr-2" />All Filters
            <number-badge
              v-show="totalAppliedFilters.length > 0"
              class="d-inline"
              variant="primary"
              :total="totalAppliedFilters"
            />
          </b-button>

          <b-modal
            :title="title"
            v-if="advancedFilter"
            id="advanced-filter-modal"
            size="xl"
            hide-footer
            no-close-on-backdrop
            static
          >
            <template #modal-header-close>
              <i class="fa fa-close" />
            </template>
            <slot name="advanced-filters" />

            <hr class="mt-4">

            <b-row>
              <b-col
                offset-lg="6"
                lg="3"
              >
                <b-button
                  id="advanced-filter-reset-button"
                  variant="link-dark"
                  block
                  @click="onFiltersReset"
                >
                  Clear All
                </b-button>
              </b-col>
              <b-col lg="3">
                <b-button
                  id="advanced-filter-apply-button"
                  variant="primary"
                  block
                  @click="applyFilters"
                >
                  Apply Filters
                </b-button>
              </b-col>
            </b-row>
          </b-modal>

          <b-button
            v-if="popoverFilter"
            id="dropdown-filter-button"
            variant="link"
            :title="title"
            v-b-tooltip.hover
            class="text-primary"
            href="#"
            tabindex="0"
          >
            <i class="fas fa-sliders-h mr-2" />All Filters
            <number-badge
              v-show="totalAppliedFilters.length > 0"
              class="d-inline"
              variant="primary"
              :total="totalAppliedFilters"
            />
          </b-button>

          <b-popover
            v-if="popoverFilter"
            triggers="focus"
            :show.sync="showPopoverFilters"
            custom-class="popover-filters p-3"
            target="dropdown-filter-button"
            placement="bottomleft"
            container="base-list-filter"
          >
            <!-- @slot Content in this slot will be contained within the modal. -->
            <slot name="popover-filters" />

            <b-button
              id="popover-filter-apply-button"
              block
              variant="primary"
              @click="applyPopoverFilters"
            >
              Filter
            </b-button>
          </b-popover>
        </b-col>

        <b-col cols="auto">
          <b-button
            v-if="columnPicker"
            id="column-picker-button"
            v-b-tooltip.hover
            v-b-modal.column-picker-modal
            variant="outline-secondary"
            title="Change table columns"
          >
            <i class="fa fa-columns" /> Columns
          </b-button>
          <b-modal
            :title="columnsTitle"
            v-if="columnPicker"
            id="column-picker-modal"
            size="xl"
            hide-footer
            no-close-on-backdrop
            static
          >
            <template #modal-header-close>
              <i class="fa fa-close" />
            </template>
            <b-row class="align-items-end">
              <b-col cols="12">
                <b-form-group
                  label="Columns"
                  label-for="column-picker-columns"
                  class="mb-4"
                >
                  <v-select
                    id="column-picker-columns"
                    v-model="selectedColumns"
                    :options="pickerColumns"
                    :get-option-key="(col) => col.key"
                    multiple
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <h4 class="mb-0">
                  Preview
                </h4>
                <p class="text-secondary">
                  Remember that the order you select columns will define the order in the table.
                </p>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-table-simple
                  responsive
                >
                  <b-thead>
                    <b-tr>
                      <b-th
                        v-for="(col, index) in selectedColumns"
                        :key="index"
                      >
                        {{ col.label }}
                      </b-th>
                    </b-tr>
                  </b-thead>
                </b-table-simple>
              </b-col>
            </b-row>

            <hr class="mt-4">

            <b-row>
              <b-col
                offset-lg="9"
                lg="3"
              >
                <b-button
                  id="column-picker-apply-button"

                  block
                  variant="primary"
                  @click="applyFilters"
                >
                  Save
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
          <slot name="right-tray" />
        </b-col>
      </b-row>

      <slot name="overflow" />
      <b-row no-gutters>
        <b-col
          v-for="pill in filters"
          :key="pill.key"
          cols="auto"
          class="mb-3"
        >
          <b-button
            variant="light"
            size="sm"
            class="pill-filter mr-2"
            @click="clearFilter(pill.key)"
          >
            {{ pill.label }}: {{ pill.value }}
            <i class="fa fa-times" />
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import { reduce as _reduce, uniq as _uniq } from 'lodash';
import NumberBadge from '@/components/shared/NumberBadge.vue';

export default {
  name: 'BaseListFilter',
  components: {
    NumberBadge,
  },
  props: {
    title: {
      type: String,
      default: 'Filters',
    },
    tableName: {
      type: String,
      required: true,
    },
    columnsTitle: {
      type: String,
      default: 'Manage Columns',
    },
    /**
     * The filters that are currently applied to the list.
     */
    appliedFilters: {
      type: Object,
      default: () => new Object(),
    },
    /**
     * The columns that the list is currently displaying.
     */
    displayedColumns: {
      type: Array,
      default: () => [],
    },
    /**
     * The columns that are available to be displayed.
     */
    availableColumns: {
      type: Array,
      default: () => [],
    },
    /**
     * Whether or not to render the column picker.
     */
    columnPicker: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether or not to render the advanced filters.
     */
    advancedFilter: {
      type: Boolean,
      default: true,
    },
    /**
     * Whether or not to render the dropdown filters.
     */
    popoverFilter: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedColumns: this.displayedColumns,
      showPopoverFilters: false,
    };
  },
  computed: {
    filters() {
      return _reduce(
        this.appliedFilters,
        (applied, filter, key) => {
          if (this.findOptionColumnByKey(key) && filter) {
            applied.push({
              value: filter,
              ...this.findOptionColumnByKey(key),
            });
          }
          return applied;
        },
        []
      );
    },
    totalAppliedFilters() {
      return Object.values(this.filters).length;
    },
    pickerColumns() {
      return this.availableColumns.filter((col) => !col.filterOnly);
    },
  },
  watch: {
    appliedFilters() {
      this.closeModals();
    },
  },
  methods: {
    /**
     * Triggers when the "Apply Filter" button is clicked.
     *
     * @property {array} selectedColumns The columns currently selected from the column.
     */
    applyFilters() {
      this.$store.dispatch('updateProfile', { ui_config: { listColumns: { ...this.$store.getters.userListColumns, [this.tableName]: _uniq(this.selectedColumns.map((column) => column.key)) } } });
      this.$emit('filters-applied', this.selectedColumns);
    },
    /**
     * Triggers when the "Reset" button is clicked.
     */
    onFiltersReset() {
      this.$emit('filters-reset');
    },
    /**
     * Triggers when one of the filter pills is clicked.
     *
     * @property {string} key The identified of the filter which has been clicked. This is the key property from the appliedFilters prop.
     */
    clearFilter(key) {
      this.$emit('filter-cleared', key);
    },
    findOptionColumnByKey(key) {
      return this.availableColumns.find((column) => column.key == key);
    },
    closeModals() {
      this.$bvModal.hide('advanced-filter-modal');
      this.$bvModal.hide('column-picker-modal');
    },
    applyPopoverFilters() {
      this.applyFilters();
      this.showPopoverFilters = false;
    }
  },
};
</script>

<style scoped>
.popover-filters {
  font-size: 1rem;
  min-width: 576px;
}
</style>
